import React from "react";
import { Tab } from "@headlessui/react";
import Header from "./Header";
import Footer from "./Footer";

const Gallery = () => {
  return (
    <>
      <Header />
      <div className="container pb-20">
        <div className="w-full max-w-md mb-10">
          <div className="flex bg-slate-200 p-1 space-x-1 rounded-lg">
            <button className="w-full bg-indigo-500 py-2.5 text-white rounded-lg">Tag Styles</button>
            <button className="w-full bg-slate-200 py-2.5 rounded-lg hover:bg-slate-300">Tag Materials</button>
            <button className="w-full bg-slate-200 py-2.5 rounded-lg hover:bg-slate-300">Signs</button>
          </div>
        </div>

        <div className="grid grid-cols-3 md:grid-cols-5">
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
            <img className="w-30" src={require('../assets/tags.png')} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
