import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const TermsOfService = () => {
  // please upload the text from http://v2.instatags.net/tos.html
  return (
    <div class="mt-8">
      <Header />
      <div class="mx-auto max-w-7xl">
        <div>
          <h1>Terms of Service ("Terms")</h1>

            <i>Last updated:
            08/28/2015.<br/></i>

            <br/><p>
              Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the
              <i>www.instatags.net</i>
              website (the "Service") operated by Technical Maintenance Support Inc. ("us", "we", or "our").
            </p>

            <br/><p>
              Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These
              Terms apply to all visitors, users and others who access or use the Service.
            </p>

            <br/><p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms
              then you may not access the Service. 
            </p>

          <h1>Termination</h1> 

            <br/><p> We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason
              whatsoever, including without limitation if you breach the Terms. 
            </p>  

            <br/><p>
              All provisions of the Terms which by their nature should survive termination shall survive termination, including,
              without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
            </p>

          <h1>Links To Other Web Sites</h1>  

            <br/><p>
              Our Service may contain links to third­party web sites or services that are not owned or controlled by Technical
              Maintenance Support Inc.
            </p>  

            <br/><p>
              Technical Maintenance Support Inc. has no control over, and assumes no responsibility for, the content, privacy policies,
              or practices of any third party web sites or services. You further acknowledge and agree that Technical Maintenance
              Support Inc. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or
              alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on
              or through any such web sites or services.
            </p>

            <br/><p>
              We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services
              that you visit.
            </p>

          <h1>Governing Law</h1>       
            <br/><p>
              These Terms shall be governed and construed in accordance with the laws of the United States of America, without
              regard to its conflict of law provisions.
            </p>  

            <br/><p>
              Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any
              provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms
              will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede
              and replace any prior agreements we might have between us regarding the Service.
            </p>

          <h1>Changes</h1>       
            <br/><p>
              We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material
              we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material
              change will be determined at our sole discretion.
            </p>  

            <br/><p>
              By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised
              terms. If you do not agree to the new terms, please stop using the Service.
            </p>

          <h1>Contact Us</h1>    

            <br/><p>If you have any questions about these Terms, <a href="http://tmsprintsystems.com/">please contact us</a>.</p>
            
        </div>
      </div>
     <Footer />
    </div>
  )
};

export default TermsOfService;
