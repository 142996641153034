import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  deleteBrand,
  fileUpload,
  fileUploadAfterSignURL,
  getSingleBand,
  updateBrand,
} from "../../../actions";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseURl } from "../../../utlilities";

const BrandDetails = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageId, setimageId] = useState("");
  const [name, setname] = useState("");
  const [alternameName, setalternameName] = useState("");
  const [supplierName, setsupplierName] = useState("");
  const [label, setlabel] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getSingleBand(params.id).then((res) => {
      debugger;
      if (res) {
        setname(res.data.name);
        setsupplierName(res.data.supplier);
        setalternameName(res.data.alternateName);
        setimageId(res.data.image);
      }
    });
  }, []);

  const uploadImage = () => {
    fileUpload(selectedImage.name).then((res) => {
      setimageId(res.data.id);
      fileUploadAfterSignURL(res.data.signUrl, selectedImage).then((res2) => {
        toast.success("Image Uploaded Successfully!");
        debugger;
      });
      debugger;
    });
  };

  const updateBrandInfo = () => {
    setisLoading(true);
    updateBrand(name, imageId, alternameName, true, params.id, supplierName).then((res) => {
      debugger;
      if (res.status == 200) {
        toast.success("Brand Updated Successfully!");
        setisLoading(false);
      } else if (res.status == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };

  const deleteNewCustomer = () => {
    setisLoading(true);
    deleteBrand(params.id).then((res) => {
      if (res.status == 200) {
        toast.success("Brand Deleted Successfully!");
        navigate("/dashboard/brands");
        setisLoading(false);
      } else if (res.status == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };
  console.log("Image", selectedImage);
  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5>Brands &gt; Brand Detail</h5>
        <div>
          <button
            className="bg-red-600 text-white px-2 py-1.5 rounded-md mr-2 disabled:opacity-75 hover:opacity-90"
            disabled={isLoading}
            onClick={() => deleteNewCustomer()}
          >
            Remove...
          </button>
          <button
            className="bg-indigo-500 px-4 py-1.5 text-white rounded-md disabled:opacity-75 hover:opacity-90"
            onClick={() => updateBrandInfo()}
            disabled={isLoading}
          >
            Save
          </button>
        </div>
      </div>

      <div className="bg-gray-50 max-w-lg mx-auto shadow-md px-3 py-3 my-4">
        <h6 className="mb-0">Image</h6>
        <p className="text-gray-500">The image primarly used for tags</p>
        <div className="flex flex-col items-center">
          {selectedImage && imageId !== "" ? (
            <img className="w-32 h-32 mb-5" src={URL.createObjectURL(selectedImage)} />
          ) : (
            <img className="w-32 h-32 mb-5" src={`${baseURl}/file/f/${imageId}`} />
          )}
          <input
            type="file"
            name="myImage"
            onChange={(event) => {
              console.log(event.target.files);
              setSelectedImage(event.target.files[0]);
            }}
          />
          <button
            className="mt-3 bg-indigo-500 text-white px-2 py-1.5 rounded-sm"
            onClick={() => uploadImage()}
          >
            Upload Image
          </button>
        </div>

        <div className="mt-2">
          <div>
            <h6 className="mb-0">Name</h6>
            <p className="text-gray-500">The name of this brand</p>
          </div>
          <input
            type="text"
            className="w-full border-2 border-gray-200 outline-gray-300 py-1 px-2"
            value={name}
            onChange={(e) => setname(e.target.value)}
          />
          <div className="px-2 py-2">
            <div>
              <input
                id="comments"
                name="comments"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 accent-indigo-500 focus:ring-indigo-500"
              ></input>
              <label className="ml-3">Active</label>
            </div>
            <div>
              <input
                id="comments"
                name="comments"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 accent-indigo-500 focus:ring-indigo-500"
              ></input>
              <label className="ml-3">Do not use for tags</label>
            </div>
            <div>
              <input
                id="comments"
                name="comments"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 accent-indigo-500 focus:ring-indigo-500"
              ></input>
              <label className="ml-3">Seasonal</label>
            </div>
            <hr />
            <div>
              <h6 className="mb-0">Supplier</h6>
              <p className="text-gray-500">Used for metrics for cost and data tracking</p>
              <input
                type="text"
                className="w-full border-2 border-gray-200 outline-gray-300 py-1 px-2"
                value={supplierName}
                onChange={(e) => setsupplierName(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <h6 className="mb-0">Labels</h6>
              <p className="text-gray-500">
                Labels can use to filter brands on per user basis as well as make it easier
              </p>
              <input
                type="text"
                className="w-full border-2 border-gray-200 outline-gray-300 py-1 px-2"
                value={label}
                onChange={(e) => setlabel(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <h6 className="mb-0">Alternate name</h6>
              <p className="text-gray-500">
                If we are using a tag style that prints the brand name Labels can use to filter
                brands on per user basis as well as make it easier
              </p>
              <input
                type="text"
                className="w-full border-2 border-gray-200 outline-gray-300 py-1 px-2"
                value={alternameName}
                onChange={(e) => setalternameName(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandDetails;
