import { Fragment, React, useEffect, useState } from "react";
import { Popover, Transition, Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";

const navigation = [
  // { name: "Home", href: "/" },
  { name: "Features", href: "/Features" },
  { name: "Customers", href: "/Customer" },
  { name: "Gallery", href: "/Gallery" },
  { name: "Pricing", href: "/Pricing" },
  { name: "Contact", href: "/Contact" },
];

const DashboardNavbar = (props) => {
  const [userDetails, setuserDetails] = useState(null);

  useEffect(() => {
    setuserDetails(JSON.parse(localStorage.getItem("userDetails")));
  }, []);
  console.log("User Details", userDetails);
  return (
    <div>
      <div className="relative bg-indigo-500">
        <div className="flex justify-between">
          <div className="relative z-10 text-white sm:pb-16 md:pb-5 lg:w-full lg:max-w-4xl lg:pb-28 xl:pb-5">
            <Popover>
              <div className="relative px-4 pt-6 sm:px-6 lg:px-8">
                <nav
                  className="text-white relative flex items-center justify-between sm:h-10 lg:justify-start"
                  aria-label="Global"
                >
                  <div className="flex flex-shrink-0 flex-grow items-center lg:flex-grow-0">
                    <div className="flex w-full items-center justify-between md:w-auto">
                      <Link to="/dashboard/start">
                        <span className="sr-only">Your Company</span>
                        <img width={100} height={100} src={require("../../assets/instatags.png")} />
                      </Link>
                      <div className="-mr-2 flex items-center md:hidden">
                        <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Open main menu</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                            />
                          </svg>
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:ml-10 md:flex md:space-x-7 md:pr-4">
                    <div className="">
                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                            Data
                            <FaChevronDown className="mt-1 ml-2" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link to="/dashboard/brands" className="no-underline">
                                    <button
                                      className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                      Brands
                                    </button>
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                            {userDetails &&
                              (userDetails.role === "DISTRIBUTOR" ||
                                userDetails.role === "ADMIN") && (
                                <div className="px-1 py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <Link to="/dashboard/Users" className="no-underline">
                                        <button
                                          className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        >
                                          Users
                                        </button>
                                      </Link>
                                    )}
                                  </Menu.Item>
                                </div>
                              )}


                            {/* {userDetails && userDetails.role === "ADMIN" && (
                              <div className="px-1 py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link to="/dashboard/Distributors" className="no-underline">
                                      <button
                                        className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                      >
                                        Distributors
                                      </button>
                                    </Link>
                                  )}
                                </Menu.Item>
                              </div>
                            )} */}


                            <div className="px-1 py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link to="/dashboard/Packages" className="no-underline">
                                    <button
                                      className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                      Packages
                                    </button>
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                            <div className="px-1 py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link to="/dashboard/Customers" className="no-underline">
                                    <button
                                      className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                      Customers
                                    </button>
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <div className="">
                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                            Tools
                            <FaChevronDown className="mt-1 ml-2" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link to="/dashboard/Activity" className="no-underline">
                                    <button
                                      className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                      Activity
                                    </button>
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link to="/dashboard/Settings" className="no-underline">
                                    <button
                                      className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                      Settings
                                    </button>
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    {/* admin  */}
                    {userDetails && userDetails.role === "ADMIN" && (
                    <div className="">
                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                            Admin
                            <FaChevronDown className="mt-1 ml-2" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {/* <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link to="/dashboard/Distributors" className="no-underline">
                                    <button
                                      className={`${
                                        active ? "bg-violet-500 text-white" : "text-gray-900"
                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        Distributors
                                    </button>
                                  </Link>
                                )}
                              </Menu.Item>
                            </div> */}

                              <div className="px-1 py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link to="/dashboard/Distributors" className="no-underline">
                                      <button
                                        className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                      >
                                        Distributors
                                      </button>
                                    </Link>
                                  )}
                                </Menu.Item>
                              </div>


                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link to="/dashboard/AdminAllUsers" className="no-underline">
                                    <button
                                      className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                      Users
                                    </button>
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    )}

                    {/* admin end  */}

                  </div>
                </nav>
              </div>

              <Transition
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
                >
                  <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                    <div className="flex items-center justify-between px-5 pt-4">
                      <div>
                        <img width={100} height={100} src={require("../../assets/instatags.png")} />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Close main menu</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="space-y-1 px-2 pt-2 pb-3">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                    <div className="px-4 pb-3">
                      <Link
                        to="/Login"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Log in
                      </Link>
                      <br />
                      <br />
                      <Link
                        to="/Signup"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Sign up
                      </Link>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
          <div>
            <div className="hidden md:ml-10 md:flex md:space-x-7 md:pr-4 mt-4">
              <div>
                <Link
                  to="/dashboard/start"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  <label className="cursor-pointer inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                    Projects
                  </label>
                </Link>
              </div>
              <div className="">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                      {userDetails && userDetails.name}
                      <FaChevronDown className="mt-1 ml-2" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/dashboard/userDetails/${userDetails.id}`}
                              className="no-underline"
                            >
                              <button
                                className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                User Settings
                              </button>
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="px-1 py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link to="/Login" className="no-underline">
                              <button
                                className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                Sign out
                              </button>
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default DashboardNavbar;
