export const materialsArray = [
  { name: "Demo Paper", value: "demo-paper" },
  { name: "6-up", value: "6-up" },
  { name: "6-Up Adhesive", value: "6-up-adhesive" },
  { name: "6-Up Economy", value: "6-up-economy" },
  { name: "18-Up", value: "18-up" },
  { name: "18-Up Adhesive", value: "18-up-adhesive" },
  { name: "18-Up Economy", value: "18-up-economy" },
  { name: "24-Up", value: "24-up" },
  { name: "24-Up Economy", value: "24-up-economy" },
  { name: "Circle K", value: "circle-k" },
  { name: "Letter", value: "letter" },
  { name: "Extra Saving", value: "extra-saving" },
];

export const stylesArray = [
  { name: "Style Tag 1", value: "ab-one" },
  { name: "Style Tag 2", value: "big-foot-yellow" },
  { name: "Style Tag 3", value: "cherokee-reward" },
  { name: "Style Tag 4", value: "dean-upc" },
  { name: "Style Tag 5", value: "fahr-yellow-medium" },
  { name: "Style Tag 6", value: "lb-modern-sale" },
  { name: "Style Tag 7", value: "lnf-brewers" },
  { name: "Style Tag 8", value: "logo" },
  { name: "Style Tag 9", value: "mitchell-gulfport" },
  { name: "Style Tag 10", value: "pacific" },
];

export const colors = [
  "#fff",
  "#000",
  "#DB3140",
  "#0E60A7",
  "#0066A1",
  "#2C3D72",
  "#968157",
  "#EE304E",
  "#ffeb3b",
  "#FF0000",
  "#FFA500",
  "#FFFF00",
  "#008000",
  "#0000FF",
  "#4B0082",
  "#EE82EE",
];

export const baseURl = process.env.REACT_APP_BACKEND_URL;
