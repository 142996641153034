import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { MdOutlineMailOutline } from "react-icons/md";
import { exportBrand, getAllUsers, importUsers, sendUserInvite } from "../../../actions";
import Spinner from "../../Common/Spinner";
import { toast } from "react-toastify";
import emptyImg from "../../../assets/empty.svg";
import { FaFileCsv } from "react-icons/fa";
import { baseURl } from "../../../utlilities";

const Users = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [userEmail, setuserEmail] = useState("");
  const [brandData, setbrandData] = useState([]);
  const [searchBox, setsearchBox] = useState("");
  const [selectedFile, setselectedFile] = useState(null);
  const [openImport, setopenImport] = useState(false);

  useEffect(() => {
    getAllUsers(searchBox).then((res) => {
      setbrandData(res.data);
      setisLoading(false);
    });
  }, [searchBox]);

  const imortBrandFunc = () => {
    setisLoading(true);
    importUsers(selectedFile).then((res) => {
      if (res.status == 201) {
        toast.success("File Imported Successfully!");
        setopenImport(false);
        getAllUsers(searchBox).then((res) => {
          setbrandData(res.data);
          setisLoading(false);
        });
      } else if (res.data.statusCode == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
      debugger;
    });
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportbrandFunc = () => {
    // Headers for each column
    let headers = ["Id,Name,Email"];

    // Convert users data to a csv
    let usersCsv = brandData.reduce((acc, user) => {
      const { id, name, email } = user;
      acc.push([id, name, email].join(","));
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "UsersData.csv",
      fileType: "text/csv",
    });
  };

  const sendUserEmail = () => {
    if (userEmail) {
      let name = userEmail.substring(0, userEmail.indexOf("@"));
      sendUserInvite(name, userEmail, "MEMBER").then((res) => {
        if (res.status == 201) {
          getAllUsers(searchBox).then((res) => {
            setbrandData(res.data);
            setisLoading(false);
          });
          toast.success("User Invited Successfully!");
          setIsOpen(false);
        } else if (res.data.statusCode == 404) {
          toast.error("Error!");
          setIsOpen(false);
          debugger;
        } else {
          setIsOpen(false);
          toast.error("Error!");
        }
        debugger;
      });
    } else {
      toast.error("Email Should not be empty");
    }
  };
  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5 className="">Users</h5>
        <div className="flex gap-4">
          <div className="">
            <input
              placeholder="Search users..."
              type="text"
              className="w-80 border-2 border-gray-200 outline-gray-300 px-1 py-1"
              onChange={(e) => setsearchBox(e.target.value)}
              value={searchBox}
            />
          </div>
          <div className="">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="bg-blue-200 inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  Import/Export <FaFileCsv className="mt-1 ml-2" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-violet-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => setopenImport(true)}
                        >
                          Import
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-violet-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => exportbrandFunc()}
                        >
                          Export
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div>
            <button
              className="mx-auto bg-indigo-500 px-4 py-2 text-white rounded-md"
              onClick={() => setIsOpen(true)}
            >
              New User
            </button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 mt-3">
          <div className="">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                  <th scope="col">Labels</th>
                  <th scope="col">Routes</th>
                  {/* <th scope="col">Projects</th> */}
                </tr>
              </thead>
              <tbody>
                {brandData.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">
                      {item.status === "PENDING" ? <MdOutlineMailOutline /> : null}
                    </th>
                    <td>
                      <div className="flex">
                        <div>
                          {item.image ? (
                            <img
                              className="w-16 h-16 mr-2"
                              src={`${baseURl}/file/f/${item.image}`}
                            />
                          ) : (
                            <img className="w-16 mr-2" src={emptyImg} />
                          )}
                        </div>
                        <div>
                          {item.status === "PENDING" ? (
                            <span className="italic text-gray-500">Waiting to confirm invite</span>
                          ) : (
                            <Link className="no-underline" to={`/dashboard/UserDetails/${item.id}`}>
                              <span> {item.name} </span>
                            </Link>
                          )}
                          <br />
                          <label className="text-gray-400">{item.email}</label>
                        </div>
                      </div>
                    </td>
                    <td>
                      <label>{item.time}</label>
                    </td>
                    <td>
                      <label>{item.routes}</label>
                    </td>
                    {/* <td>{item.projects}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Transition appear show={openImport} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setopenImport(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Upload CSV file:
                  </Dialog.Title>

                  <input
                    type="file"
                    accept=".csv"
                    name="myImage"
                    onChange={(event) => {
                      console.log(event.target.files);
                      setselectedFile(event.target.files[0]);
                    }}
                  />
                  <div className="mt-4">
                    <button
                      disabled={isLoading}
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => imortBrandFunc()}
                    >
                      Upload
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Invite User
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Invite users via email</p>
                    <div className="w-full">
                      <input
                        type="email"
                        className="w-full border-2 border-gray-200 outline-gray-300"
                        value={userEmail}
                        onChange={(e) => setuserEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => sendUserEmail()}
                    >
                      Invite
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Users;
