import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Features = () => {
  return (
    <>
      <Header />
      <div className="p-5 bg-indigo-500 text-white text-center">
        <h1>Let's dig into Insta Tags</h1>
        <h5>Right tool for the right poeple.</h5>
      </div>

      <div className="flex justify-center p-4 text-center">
        <div className="max-w-sm md:px-20 py-3 rounded-full text-center bg-indigo-500 text-white px-6">
          <label className="md:text-2xl text-xl">For your sales team:</label>
        </div>
      </div>
      <div className="flex-col items-center justify-center text-center space-y-4 text-slate-600">
        <h1>Bring your signshop with you.</h1>
        <label>Whether you're making tags or signs, you've got access to all of your POS.</label>
        <br />
        <label>Just tap in what you need: brand, package and price.</label>
        <br />
        <label>Hit send & your signshop prints, so you can get back to selling.</label>
        <div className="flex justify-center">
          <div className="max-w-4xl">
            <img className="" src={require("../assets/tablet.png")} />
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div className="container py-5 grid grid-cols-1 md:grid-cols-2 justify-center">
          <div className="max-w-lg">
            <img src={require("../assets/bg-2.jpg")} />
          </div>
          <div className="max-w-md text-center">
            <h4 className="text-center">World class app. iPhone or iPad.</h4>
            <label>
              Because it's a real native app, you can be sure that Instatags fits right into your
              day-to-day tool set.
            </label>
            <div className="grid justify-center mt-5">
              <img className="" src={require("../assets/buttons.jpg")} />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="py-10">
        <h1 className="text-center">Any look, any style, any size.</h1>

        <div className="container grid grid-cols-1 md:grid-cols-3 gap-20">
          <div className="shadow-lg px-4 py-2 w-max-sm">
            <div>
              <img className="w-full" src={require("../assets/Image -3.jpg")} />
            </div>
            <br />
            <label className="font-bold">Your Style</label>
            <p className="mt-2">
              {" "}
              Whether it's graphics you create in-house or POS straight from your suppliers,
              Instatags gives you the power to use it all so your marketing is on point. No more
              cookie cutter, generic looking POS. Use the real stuff so you can look perfect in the
              market.
            </p>
          </div>
          <div className="shadow-lg px-4 py-2 w-max-sm">
            <div>
              <img className="w-full" src={require("../assets/Image -3.jpg")} />
            </div>
            <br />
            <label className="font-bold">Your Style</label>
            <p className="mt-2">
              {" "}
              Whether it's graphics you create in-house or POS straight from your suppliers,
              Instatags gives you the power to use it all so your marketing is on point. No more
              cookie cutter, generic looking POS. Use the real stuff so you can look perfect in the
              market.
            </p>
          </div>
          <div className="shadow-lg px-4 py-2 w-max-sm">
            <div>
              <img className="w-full" src={require("../assets/Image -3.jpg")} />
            </div>
            <br />
            <label className="font-bold">Your Style</label>
            <p className="mt-2">
              {" "}
              Whether it's graphics you create in-house or POS straight from your suppliers,
              Instatags gives you the power to use it all so your marketing is on point. No more
              cookie cutter, generic looking POS. Use the real stuff so you can look perfect in the
              market.
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div className="text-center">
        <h1>Your signs - your look.</h1>
        <div className="mt-4 space-y-2">
          <label>Adding your signage to Insta Tags is simple: just drag & drop.</label> <br />
          <label>Then use the your fonts, colors and price point style.</label>
          <br />
          <label>Your branding is perfect - every time.</label>
        </div>
        <div className="grid justify-center">
          <img src={require("../assets/dash.jpg")} />
        </div>
      </div>
      <hr />
      <div className="text-center">
        <h1>Your real time to-do list.</h1>
        <div className="mt-4 space-y-2">
          <label>You can finally stop using email to manage your tag and sign requests.</label>{" "}
          <br />
          <label>With Activity, see what needs to be printed at a glance.</label>
          <br />
          <label>
            Easily merge projects to save tons of material and make mass printing a breeze.
          </label>
        </div>
        {/* <div className="mt-10">
          <dl className="grid md:grid-cols-3 justify-center">
            <div></div>
            <div>
              <iframe
                className="w-full aspect-video"
                src="https://www.youtube.com/embed/tgbNymZ7vqY"
              ></iframe>
            </div>
            <div></div>
          </dl>
        </div> */}
      </div>
      <hr />
      <div className="text-center py-5">
        <h1>Using sign request softwares?</h1>
        <div>
          <div className="grid justify-center mt-4">
            {" "}
            <div>
              <img className="max-w-sm w-10 h-15" src={require("../assets/x-mark.jpg")} />
            </div>
          </div>
          <label className="max-w-md">
            While great for accounting, sign request software only solves a small part of the
            problem. You're still making priced signage, one by one.
          </label>
        </div>

        <div>
          <div className="grid justify-center mt-4">
            {" "}
            <div>
              <img className="max-w-sm w-10 h-15" src={require("../assets/check-mark.jpg")} />
            </div>
          </div>
          <label className="max-w-md">
            Instatags creates signage that would otherwise be done by hand. It fits right in with
            any request software by actually making the signs your sales team submits.
          </label>
        </div>
      </div>

      <div className="py-5 px-2">
        <h1 className="text-center">Tons of extras to make your day easier.</h1>
        <div className="container grid grid-cols-2 gap-x-4 gap-y-8 mt-4">
          <div className="flex justify-end">
            <img className="max-w-md" src={require("../assets/Image -3.jpg")} />
          </div>
          <div className="max-w-sm">
            <div>
              <label className="font-bold text-lg">Quick setup</label>
              <p className="text-md text-slate-600">
                Adding your graphics is as simple as dragging and dropping your files, Instatags
                does the rest. Getting your team using Instatags is as straighforward as adding
                their email addresses. Less time setting up and more time getting things done.
              </p>
            </div>
          </div>
          <div className="flex justify-end">
            <img className="max-w-md" src={require("../assets/Image -3.jpg")} />
          </div>
          <div className="max-w-sm">
            <div>
              <label className="font-bold text-lg">Perfect shelf tags</label>
              <p className="text-md text-slate-600">
                Adding your graphics is as simple as dragging and dropping your files, Instatags
                does the rest. Getting your team using Instatags is as straighforward as adding
                their email addresses. Less time setting up and more time getting things done.
              </p>
            </div>
          </div>
          <div className="flex justify-end">
            <img className="max-w-md" src={require("../assets/Image -3.jpg")} />
          </div>
          <div className="max-w-sm">
            <div>
              <label className="font-bold text-lg">Quick setup</label>
              <p className="text-md text-slate-600">
                Adding your graphics is as simple as dragging and dropping your files, Instatags
                does the rest. Getting your team using Instatags is as straighforward as adding
                their email addresses. Less time setting up and more time getting things done.
              </p>
            </div>
          </div>
        </div>
        <div className="py-5">
          <h1 className="text-center">...and tons more.</h1>
          <div className="text-center">
            <label>Insta Tag was built from the ground up to get you making signage fast.</label>
            <br />
            <label className="font-bold">Still not sure? Need more info?</label>
          </div>
          <div className="flex justify-center p-4 text-center">
            <div className="max-w-lg md:px-10 px-4 py-3 rounded-full text-center bg-indigo-500 text-white">
              <label className="md:text-2xl text-lg">Get started now, it's free for 30 days</label>
            </div>
          </div>
          <div className="flex justify-center text-center">
            <label className="text-center md:basis-1/3 basis-1/1">
              Seriously, try it absolutely free with no credit card required. Let’s find out if
              Instatags is right for you. Spoiler: it probably is!
            </label>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Features;
