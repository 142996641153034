import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Pricing = () => {
  return (
    <div>
      <Header />
      <div className="text-center">
        <p className="text-3xl  leading-8 tracking-tight text-indigo-500 sm:text-4xl">
          Straightforward Pricing
        </p>
        <p className="mt-2 text-2xl  leading-8 tracking-tight text-indigo-500 sm:text-4xl">
          No hidden fees, surprises or contracts.
        </p>

        <img className="mx-auto mb-10" src={require("../assets/TagsPrice.png")} />

        {/* <div className="inline-flex ;">
          <button className="bg-indigo-200 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded-l-full">
            Annual Pricing
          </button>
          <button className="bg-indigo-500 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded-r-full">
            Monthly Pricing
          </button>
        </div>

        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate
          veritatis in accusamus quisquam.
        </p>

        <div className="container mx-auto p-4 grid md:grid-cols-2 justify-items-center">
          <div className="w-full max-w-md shadow-lg justify-center text-center px-4 py-5 rounded-lg">
            <div class="">
              <img
                className=" mx-auto"
                width={100}
                height={100}
                src={require("../assets/instatags.png")}
              />
            </div>
            <div>
              <label className="font-bold text-xl mt-2 mb-2">Basic</label>

              <p className="mt-2 max-w-2xl text-gray-500 lg:mx-auto">
                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum
                cupiditate veritatis in accusamus quisquam.
              </p>
            </div>
            <div className="text-left">
              <label className="block font-bold mt-2 mb-2 text-xl">$1398/Year</label>
              <a href="#" className="block font-small text-blue-600 dark:text-blue-500 ">
                Read more
              </a>

              <label className=" mt-2">Advanced</label>

              <p className=" max-w-2xl text-gray-500 lg:mx-auto">
                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum
                cupiditate veritatis in accusamus quisquam.
              </p>

              <label className=" mt-2">Advanced</label>

              <p className=" max-w-2xl text-gray-500 lg:mx-auto">
                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum
                cupiditate veritatis in accusamus quisquam.
              </p>

              <label className=" mt-2">Advanced</label>

              <p className=" max-w-2xl text-gray-500 lg:mx-auto">
                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum
                cupiditate veritatis in accusamus quisquam.
              </p>
            </div>
            <div className="flex justify-center p-4 text-center">
              <div className="max-w-sm md:px-3 py-2 rounded-full text-center bg-indigo-500 text-white px-3">
                <label className="md:text-lg text-lg">Buy Now</label>
              </div>
            </div>
          </div>
          <div className="w-full max-w-md shadow-lg justify-center text-center px-4 py-5 rounded-lg">
            <div class="">
              <img
                className=" mx-auto"
                width={100}
                height={100}
                src={require("../assets/instatags.png")}
              />
            </div>
            <div>
              <label className="font-bold text-xl mt-2 mb-2">Advanced</label>

              <p className="mt-2 max-w-2xl text-gray-500 lg:mx-auto">
                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum
                cupiditate veritatis in accusamus quisquam.
              </p>
            </div>
            <div className="text-left">
              <label className="block font-bold mt-2 mb-2 text-xl">$1398/Year</label>
              <a href="#" className="block font-small text-blue-600 dark:text-blue-500 ">
                Read more
              </a>

              <label className=" mt-2">Advanced</label>

              <p className=" max-w-2xl text-gray-500 lg:mx-auto">
                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum
                cupiditate veritatis in accusamus quisquam.
              </p>

              <label className=" mt-2">Advanced</label>

              <p className=" max-w-2xl text-gray-500 lg:mx-auto">
                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum
                cupiditate veritatis in accusamus quisquam.
              </p>

              <label className=" mt-2">Advanced</label>

              <p className=" max-w-2xl text-gray-500 lg:mx-auto">
                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum
                cupiditate veritatis in accusamus quisquam.
              </p>
            </div>
            <div className="flex justify-center p-4 text-center">
              <div className="max-w-sm md:px-3 py-2 rounded-full text-center bg-indigo-500 text-white px-3">
                <label className="md:text-lg text-lg">Buy Now</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center p-4 text-center">
        <div className="max-w-sm md:px-20 py-3 rounded-full text-center bg-indigo-500 text-white px-6">
          <label className="md:text-2xl text-xl">For your sales team:</label>
        </div>
      </div>
      <hr class="my-4 mx-auto h-1 bg-indigo-300 border-0 md:my-10 dark:bg-gray-700" />
      <div className="grid grid-cols-1 justify-items-center py-10">
        <div className="max-w-lg px-4">
          <p className="">
            <h4 className="font-bold">Common questions on pricing</h4>
            Nope! <br />
            Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat
            commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua
            ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat
            veniam occaecat fugiat aliqua.
          </p>
        </div> */}
      </div>
      {/* <div className="mt-10 bg-indigo-100 py-12">
        <dl className="grid md:grid-cols-3 justify-center">
          <div></div>
          <div>
            <iframe
              className="w-full aspect-video"
              src="https://www.youtube.com/embed/tgbNymZ7vqY"
            ></iframe>
          </div>
          <div></div>
        </dl>
      </div> */}
      <Footer />
    </div>
  );
};
export default Pricing;
