import React from "react";

const DeanUpc = (props) => {
  return (
    <div className="mt-4">
      <div
        className="w-100 flex gap-3 border border-gray-100"
        style={{ backgroundColor: props.backgroundColor }}
      >
        <div className="w-full mt-4 text-center">
          <img className="m-auto w-40 h-10" src={require("../../../assets/instatags.png")} />
          <p className="p-1 font-sm font-bold" style={{ color: props.priceColor }}>
            ENJOY RESPONSIBLY
          </p>
        </div>
        <div className="w-full text-center">
          <p className="font-bold mb-0" style={{ color: props.priceColor }}>
            4pk 16oz Cans
          </p>
          <p className="mb-0" style={{ color: props.priceColor }}>
            <span className="text-3xl relative -top-5 font-[1000]">$</span>
            <span className="text-6xl font-[1000]">4 99</span>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeanUpc;
