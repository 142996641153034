import React, { useState, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSinglePackages, updatePackage } from "../../../actions";
import { toast } from "react-toastify";
import Spinner from "../../Common/Spinner";

const PackageDetails = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setname] = useState("");
  const [aliases, setaliases] = useState("");
  const [totalOz, settotalOz] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    getSinglePackages(params.id).then((res) => {
      debugger;
      if (res) {
        setname(res.data.name);
        settotalOz(res.data.once);
        setaliases(res.data.alias);
      }
    });
  }, []);

  const updateNewPackage = () => {
    setisLoading(true);
    updatePackage(name, aliases, totalOz, params.id).then((res) => {
      debugger;
      if (res.status == 200) {
        toast.success("Package Updated Successfully!");
        setIsOpen(false);
        setisLoading(false);
      } else if (res.status == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };
  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5>Packages &gt; Package Details</h5>
        <button
          className="bg-indigo-500 px-4 text-white rounded-sm disabled:opacity-75 hover:opacity-90"
          onClick={() => updateNewPackage()}
          disabled={isLoading}
        >
          Save
        </button>
      </div>

      <div className="bg-gray-50 max-w-lg mx-auto shadow-md px-3 py-3 my-4">
        <h6 className="mb-0">Name</h6>
        <p className="text-gray-500 mb-0">
          The text that will appear is tags and signs, tpically accompanied by a price point
        </p>
        <input
          type="text"
          className="w-full border-2 border-gray-200 outline-gray-300 py-1 px-2"
          onChange={(e) => setname(e.target.value)}
          value={name}
        />

        <div className="mt-3">
          <div>
            <h6 className="mb-0">Aliases</h6>
            <p className="text-gray-500 mb-0">
              Useful when importing data that doesn't use your exact package name
            </p>
          </div>
          <input
            type="text"
            className="w-full border-2 border-gray-200 outline-gray-300 py-1 px-2"
            onChange={(e) => setaliases(e.target.value)}
            value={aliases}
          />
        </div>

        <div className="mt-3">
          <div>
            <h6 className="mb-0">Total ounces</h6>
            <p className="text-gray-500 mb-0">
              Used for tag styles that require a cost per ounce to be displayed
            </p>
          </div>
          <input
            type="text"
            className="w-full border-2 border-gray-200 outline-gray-300 py-1 px-2"
            onChange={(e) => settotalOz(e.target.value)}
            value={totalOz}
          />
        </div>

        <button className="bg-red-600 text-white px-2 py-1.5 rounded-md mt-3">Remove...</button>
      </div>
    </div>
  );
};

export default PackageDetails;
