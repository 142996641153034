import React from "react";
import Nav from "react-bootstrap/Nav";
import { BsTelephoneFill } from "react-icons/bs";
import { AiFillMail } from "react-icons/ai";



const navigation = [
  { name: "Home", href: "/" },
  // { name: "Features", href: "/Features" },
  // { name: "Customers", href: "/Customer" },
  // { name: "Gallery", href: "/Gallery" },
  { name: "Pricing", href: "/Pricing" },
  { name: "TermsOfService", href: "/TermsOfService" },
  { name: "PrivacyPolicy", href: "/PrivacyPolicy" },
];

const Footer = () => {
  return (
    <div className="bg-indigo-500 p-5 footer-main">
      <div class="sm:flex lg:justify-start gap-4">
      {/* sm:flex lg:justify-start */}
        <div class="col-2">
          <img width={200} height={200} src={require("../assets/instatags.png")} />
          {/* <p className="text-white">
            Dummy Text is adding here, later on change the text here after sometimes.Dummy Text is
            adding here, later on change the text here after sometimes.
          </p> */}
        </div>
        {/* <div style={{}}> */}
          <div class="col-2 text-center">
            <h5 className="underline underline-offset-8 decoration-black text-zinc-100">Links</h5>
            <ul class="list-none pl-0 text-white font-bold mt-4">
              {/*<li>Home</li>
              <li>Features</li>
              <li>Customers</li>
              <li>Gallery</li> */}
              <Nav.Link href="/Pricing">Pricing</Nav.Link>
              <Nav.Link href="/TermsOfService">Terms of Service</Nav.Link>
              <Nav.Link href="/PrivacyPolicy">Privacy Policy</Nav.Link>
            </ul>
          </div>
          <div class="col-4 text-center">
            <h5 className="underline underline-offset-8 decoration-black text-zinc-100">Contact Us</h5>
            <ul class="list-none pl-0 text-white font-bold mt-4">
              <li>We look forward to hearing from you!</li>
              <li>Main phone: <a class="text-white" href="tel:8007837568">+1 (800) 783-7568</a></li>
              <li>Sales team email: <a class="text-white" href='mailto:sales@instatags.net'>sales@instatags.net</a></li>
              <li>Support team email: <a class="text-white" href="mailto:support@instatags.net">support@instatags.net</a></li>
            </ul>
          </div>
          <div class="col-4 text-center">
            <h5 className="underline underline-offset-8 decoration-black text-zinc-100">Location</h5>
            <ul class="list-none pl-0 text-white font-bold mt-4">
              <li>&copy; 2024 Technical Maintenance Support Inc.</li>
              <li>20651 Prism Place, Lake Forest, CA 925630</li>
              <li><a class="text-white" href="tel:800-783-7568"><BsTelephoneFill display={"inline"}/>800-783-7568</a> | <AiFillMail display={"inline"}/> <a class="text-white" href="mailto:support@instatags.net">support@instatags.net</a> | <AiFillMail display={"inline"}/> <a class="text-white" href="mailto:info@tmsprintsystems.com">info@tmsprintsystems.com</a></li>
            </ul>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Footer;
