import React from "react";

const AbOne = (props) => {
  return (
    <div>
      <div className="flex mt-4">
        <div
          className="flex gap-3 border border-gray-100 px-3 py-3"
          style={{ backgroundColor: props.backgroundColor }}
        >
          <div className="w-full flex">
            <img className="" src={require("../../../assets/instatags.png")} />
          </div>
          <div className="w-full my-auto text-center" style={{ color: props.priceColor }}>
            <p className="mb-0">
              <span className="text-xl font-[1000] m-auto">$</span>{" "}
              <span className="text-5xl font-[1000]"> 25.99</span>
            </p>
            <p className="my-auto p-1">4pk 16oz Cans</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AbOne;
