import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { addBrand, exportBrand, getAllBands, importBrand } from "../../../actions";
import { toast } from "react-toastify";
import Spinner from "../../Common/Spinner";
import { FaChevronDown, FaFileCsv } from "react-icons/fa";
import { BsFilterLeft } from "react-icons/bs";
import moment from "moment";
import emptyImg from "../../../assets/NoImg.png";
import { baseURl } from "../../../utlilities";

const Brands = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [brandName, setbrandName] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [searchBox, setsearchBox] = useState("");
  const [orderBy, setorderBy] = useState("name");
  const [sortBy, setsortBy] = useState("DESC");
  const [selectedFile, setselectedFile] = useState(null);
  const [openImport, setopenImport] = useState(false);
  const [brandData, setbrandData] = useState(
    []
    //   [
    //   {
    //     id: 1,
    //     name: "Coca Cola",
    //     byUser: "user@gmail.com",
    //     img: "https://images.unsplash.com/photo-1535990379313-5cd271a2da2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y29jYSUyMGNvbGF8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    //     time: "8 days ago",
    //     supplier: "Shaun",
    //   },
    //   {
    //     id: 2,
    //     name: "M & M",
    //     byUser: "user@gmail.com",
    //     img: "https://images.unsplash.com/photo-1612312567046-79b06f17fb65?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8bSUyNm1zfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    //     time: "12 days ago",
    //     supplier: "John",
    //   },
    //   {
    //     id: 3,
    //     name: "KitKat",
    //     byUser: "user@gmail.com",
    //     img: "https://images.unsplash.com/photo-1558403317-978b523a27ff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8a2l0a2F0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    //     time: "24 days ago",
    //     supplier: "Micheal",
    //   },
    // ]
  );

  const addNewBrand = () => {
    addBrand(brandName).then((res) => {
      if (res.status == 201) {
        toast.success("Brand Addedd Successfully!");
        setIsOpen(false);
        getAllBands(searchBox, orderBy, sortBy).then((res) => {
          setbrandData(res.data);
          setisLoading(false);
        });
      } else if (res.data.statusCode == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };

  const imortBrandFunc = () => {
    setisLoading(true);
    importBrand(selectedFile).then((res) => {
      if (res.status == 201) {
        toast.success("File Imported Successfully!");
        setopenImport(false);
        getAllBands(searchBox, orderBy, sortBy).then((res) => {
          setbrandData(res.data);
          setisLoading(false);
        });
      } else if (res.data.statusCode == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
      debugger;
    });
  };

  // const exportbrandFunc = () => {
  //   exportBrand().then((res) => {
  //     debugger;
  //     if (res.status == 201) {
  //       toast.success("File Imported Successfully!");
  //       setopenImport(false);
  //     } else if (res.data.statusCode == 404) {
  //       toast.error("Error!");
  //       debugger;
  //     } else {
  //       toast.error("Error!");
  //     }
  //     debugger;
  //   });
  // };

  useEffect(() => {
    getAllBands(searchBox, orderBy, sortBy).then((res) => {
      setbrandData(res.data);
      setisLoading(false);
    });
  }, [searchBox, orderBy, sortBy]);

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportbrandFunc = () => {
    // Headers for each column
    let headers = ["Id,Name,AlternateName,Supplier"];

    // Convert users data to a csv
    let usersCsv = brandData.reduce((acc, user) => {
      const { id, name, alternateName, supplier } = user;
      acc.push([id, name, alternateName, supplier].join(","));
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "brandsData.csv",
      fileType: "text/csv",
    });
  };

  console.log("CSV", selectedFile);
  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5>Brands</h5>
        <div className="flex gap-4">
          <input
            placeholder="Search brands..."
            type="text"
            className="w-80 border-2 border-gray-200 outline-gray-300 px-1 py-1"
            onChange={(e) => setsearchBox(e.target.value)}
            value={searchBox}
          />

          <div className="">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="bg-blue-200 inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  Filter <BsFilterLeft className="mt-1 ml-2" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-violet-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => {
                            setorderBy("name");
                            setsortBy("ASC");
                          }}
                        >
                          Names (A-Z)
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-violet-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => {
                            setorderBy("name");
                            setsortBy("DESC");
                          }}
                        >
                          Names (Z-A)
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-violet-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => {
                            setorderBy("name");
                            setsortBy("ASC");
                          }}
                        >
                          Order by name
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-violet-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => {
                            setorderBy("createdAt");
                            setsortBy("DESC");
                          }}
                        >
                          Order by created time
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="bg-blue-200 inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  Import/Export <FaFileCsv className="mt-1 ml-2" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-violet-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => setopenImport(true)}
                        >
                          Import
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? "bg-violet-500 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => exportbrandFunc()}
                        >
                          Export
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <button
            className="bg-indigo-500 px-4 py-1 text-white rounded-md hover:bg-indigo-600"
            onClick={() => setIsOpen(true)}
          >
            New
          </button>
        </div>
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 mt-3">
          <div className="">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Alternate Name</th>
                  <th scope="col">Supplier</th>
                  <th scope="col">Created At</th>
                </tr>
              </thead>
              <tbody>
                {brandData.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <Link className="no-underline" to={`/dashboard/BrandDetails/${item.id}`}>
                        <div className="flex">
                          {item.image ? (
                            <img className="w-16" src={`${baseURl}/file/f/${item.image}`} />
                          ) : (
                            <img className="w-16" src={emptyImg} />
                          )}
                          <label className="mt-2 ml-2 text-black cursor-pointer">{item.name}</label>
                        </div>
                      </Link>
                    </td>
                    <td className="">
                      {/* <label>{item.time}</label> <br />{" "} */}
                      <label className="text-gray-400">{item.alternateName}</label>
                    </td>
                    <td>{item.supplier}</td>
                    <td>{moment(item.createdAt).format("MMM Do YY")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Brand from a list
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Add brand name below</p>
                    <div className="w-full">
                      <textarea
                        vallu={brandName}
                        onChange={(e) => setbrandName(e.target.value)}
                        rows={5}
                        className="w-full border-2 border-gray-200 outline-gray-300"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => addNewBrand()}
                    >
                      Create Brand
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={openImport} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setopenImport(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Upload CSV file:
                  </Dialog.Title>

                  <input
                    type="file"
                    name="myImage"
                    accept=".csv"
                    onChange={(event) => {
                      console.log(event.target.files);
                      setselectedFile(event.target.files[0]);
                    }}
                  />
                  <div className="mt-4">
                    <button
                      disabled={isLoading}
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => imortBrandFunc()}
                    >
                      Upload
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Brands;
