import React, { useState, Fragment, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaDownload, FaPlus, FaTimes } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import {
  createProjectTag,
  deleteProject,
  deleteProjectLine,
  getAllBands,
  getAllMaterials,
  getAllPackages,
  getAllStyles,
  getProjectTag,
  getSingleProject,
  makeProject,
  updateProject,
  updateProjectLine,
} from "../../../actions";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../Common/Spinner";
import { CirclePicker } from "react-color";
import AbOne from "../TagsStyles/Ab-One";
import Logo from "../TagsStyles/Logo";
import Brewers from "../TagsStyles/Brewers";
import MitchellGuld from "../TagsStyles/MitchellGulf";
import Pacific from "../TagsStyles/Pacific";
import BigFoot from "../TagsStyles/BigFoot";
import Cherokee from "../TagsStyles/Cherokee";
import DeanUpc from "../TagsStyles/DeanUpc";
import FahYellow from "../TagsStyles/FahrYellow";
import { Document, Page, pdfjs } from "react-pdf";
import { baseURl, colors } from "../../../utlilities";

const Projects = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [brandData, setbrandData] = useState([]);
  const [packagesData, setpackagesData] = useState([]);
  const [packageId, setpackageId] = useState("");
  const [quantity, setquantity] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [tagData, settagData] = useState([]);
  const [materials, setmaterials] = useState([]);
  const [styles, setstyles] = useState([]);
  const [defaultMaterial, setdefaultMaterial] = useState("");
  const [defaultStyle, setdefaultStyle] = useState("");
  const [projectName, setprojectName] = useState("");
  const [pdfFile, setpdfFile] = useState("");
  const [btnLoading, setbtnLoading] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [backgroundColor, setbackgroundColor] = useState("#fff");
  const [priceColor, setpriceColor] = useState("#000");
  const [lineModal, setlineModal] = useState(false);
  const [lineBrands, setlineBrands] = useState([]);
  const [linePackage, setlinePackage] = useState("");
  const [lineQuantity, setlineQuantity] = useState("");
  const [lineCopies, setlineCopies] = useState("0");
  const [lineId, setlineId] = useState("");
  const [fileId, setfileId] = useState("");
  const [alternateName, setalternateName] = useState("");

  const componentRef = useRef();
  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    onBeforePrint: async () => {
      await setbtnLoading(false);
    },
    onAfterPrint: async () => {
      await updateProject(
        projectName,
        defaultStyle,
        defaultMaterial,
        params.id,
        componentRef.current.innerHTML
      ).then((res) => {
        if (res.status == 200) {
          // toast.success("Info Updated Successfully!");
          setisLoading(false);
        } else if (res.status == 404) {
          toast.error("Error!");
          debugger;
        } else {
          toast.error("Error!");
        }
      });
    },
    content: () => componentRef.current,
  });

  const params = useParams();

  console.log(selectedOption);

  useEffect(() => {
    const distributor = JSON.parse(localStorage.getItem("userDetails"));

    getSingleProject(params.id).then((res) => {
      setprojectName(res.data.name);
      setdefaultMaterial(res.data.material);
      setdefaultStyle(res.data.style);
      setpdfFile(res.data.pdfFile);
      setbackgroundColor(res.data.backgroudColor === null ? "#fff" : res.data.backgroudColor);
      setpriceColor(res.data.priceColor === null ? "#000" : res.data.priceColor);
    });

    getAllBands("", "name", "DESC").then((res) => {
      if (res.data) {
        setbrandData(
          res.data.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        );
      }
      // setisLoading(false);
    });

    getAllPackages().then((res) => {
      setpackagesData(res.data);
      // setisLoading(false);
    });

    getProjectTag(params.id).then((res) => {
      settagData(res.data);
      // setisLoading(false);
    });

    getAllMaterials(distributor.distributorId).then((res) => {
      setmaterials(res.data);
      getAllStyles(distributor.distributorId).then((res2) => {
        setstyles(res2.data);
      });
    });
  }, []);

  const createTagsFunc = () => {
    setisLoading(true);
    const filterBrandId = selectedOption.map((item) => {
      return item.value;
    });

    createProjectTag(params.id, filterBrandId, packageId, quantity).then((res) => {
      if (res.status == 201) {
        toast.success("Tags Addedd Successfully!");
        getProjectTag(params.id).then((res) => {
          settagData(res.data);
          setisLoading(false);
        });
      } else if (res.data.statusCode == 404) {
        toast.error("Error!");
        setisLoading(false);
        debugger;
      } else {
        setisLoading(false);
        toast.error("Error!");
      }
    });
  };

  const makePdf = () => {
    makeProject(params.id).then((res) => {
      if (res.status == 201) {
        debugger;
        getSingleProject(params.id).then((res) => {
          debugger;
          setfileId(res.data.file[0]);
          setbtnLoading(false);
          window.open(`${baseURl}/file/f/view/preview.pdf?id=${res.data.file[0]}`);
        });
      } else if (res.data.statusCode == 404) {
        toast.error("Error!");
        setisLoading(false);
        debugger;
      } else {
        setisLoading(false);
        toast.error("Error!");
      }
    });
  };

  const updateProjectFunc = () => {
    updateProject(
      projectName,
      defaultStyle,
      defaultMaterial,
      params.id,
      pdfFile,
      backgroundColor,
      priceColor
    ).then((res) => {
      if (res.status == 200) {
        toast.success("Info Updated Successfully!");
        setisLoading(false);
        setIsOpen(false);
      } else if (res.status == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };

  const updateProjectLineFunc = () => {
    const filterBrandId = selectedOption.map((item) => {
      return item.value;
    });
    updateProjectLine(
      params.id,
      filterBrandId,
      packageId,
      lineQuantity,
      parseInt(lineCopies),
      alternateName,
      backgroundColor,
      priceColor,
      lineId
    ).then((res) => {
      if (res.status == 200) {
        toast.success("Info Updated Successfully!");
        setisLoading(false);
        getProjectTag(params.id).then((res) => {
          settagData(res.data);
        });
        setlineModal(false);
        setIsOpen(false);
      } else if (res.status == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };

  const deleteLineFunc = () => {
    setisLoading(true);
    setlineModal(false);
    deleteProjectLine(params.id, lineId).then((res) => {
      if (res.status == 200) {
        toast.success("Line Deleted Successfully!");
        getProjectTag(params.id).then((res) => {
          settagData(res.data);
          setisLoading(false);
        });
      } else if (res.status == 404) {
        toast.error("Error!");
        setisLoading(false);
        debugger;
      } else {
        setisLoading(false);
        toast.error("Error!");
      }
    });
  };

  const deleteProjectFunc = () => {
    deleteProject(params.id).then((res) => {
      if (res.status == 200) {
        navigate("/dashboard/start");
        toast.success("Project Deleted Successfully!");
      } else if (res.status == 404) {
        toast.error("Error!");
        setisLoading(false);
        debugger;
      } else {
        setisLoading(false);
        toast.error("Error!");
      }
    });
  };

  const openLineModal = (data) => {
    debugger;
    setlineId(data.id);
    setlineModal(true);
    setlinePackage(data.package.name);
    setpackageId(data.packageId);
    setlineQuantity(data.quantity);
    setlineCopies(data.copies);
    setSelectedOption(
      data.brand.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );
  };

  console.log("Bacground", backgroundColor);
  console.log("Selected Brands", selectedOption);
  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5>Projects &gt; Project # 01</h5>

        <div className="flex gap-5">
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={() => setIsOpen(true)}
          >
            <div className="flex">
              <FiSettings className="mt-1 mr-2" /> Settings
            </div>
          </button>

          <button
            className="bg-indigo-500 px-4 text-white rounded-md hover:bg-indigo-600 disabled:opacity-75"
            disabled={btnLoading}
            onClick={() => {
              makePdf();
              setbtnLoading(true);
            }}
          >
            <div className="flex">
              Make <FaDownload className="mt-1 ml-2" />
              {btnLoading && (
                <div className="ml-3">
                  <svg
                    class="inline w-4 h-4 text-white animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              )}
            </div>
          </button>
        </div>
      </div>

      <div className="flex gap-3 bg-gray-50 shadow-md px-3 py-3 my-4">
        <div className="w-full">
          <p className="text-gray-500 mb-0">Select the brand from here</p>
          <Select
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={brandData}
            isMulti={true}
          />
        </div>
        <div className="w-full">
          <p className="text-gray-500 mb-0">Select the package from here</p>
          <select
            className="w-full border-2 border-gray-200 outline-gray-300 py-1.5 px-2 rounded-md"
            onChange={(e) => setpackageId(e.target.value)}
          >
            <option>Packages</option>
            {packagesData.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className="w-full">
          <div>
            <p className="text-gray-500 mb-0">Add amount here</p>
          </div>
          <input
            value={quantity}
            onChange={(e) => setquantity(e.target.value)}
            type="text"
            className="w-full border-2 border-gray-200 outline-gray-300 py-1.5 px-2 rounded-md"
          />
        </div>

        <div className="m-auto">
          <button
            className="bg-green-600 px-2 py-2 mt-4 text-white rounded-sm hover:bg-green-700"
            disabled={isLoading}
            onClick={() => createTagsFunc()}
          >
            <FaPlus />
          </button>
        </div>
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        tagData.map((item) => (
          <div
            key={item.id}
            className="grid grid-cols-3 justify-center gap-3 bg-white border border-gray-100 px-3 py-3 cursor-pointer"
            onClick={() => openLineModal(item)}
          >
            <div className="grid grid-cols">
              {item.brand?.map((brand) => (
                <div key={brand.id} className="w-full flex mt-2">
                  <img className="w-20" src={`${baseURl}/file/f/${brand.image}`} />
                  <h6 className="ml-2 my-auto">{brand.name}</h6>
                </div>
              ))}
            </div>
            <div className="w-2xl my-auto">
              <p className="text-gray-500 my-auto">{item.package.name}</p>
            </div>

            <div className="w-full my-auto flex justify-between">
              <p className="text-gray-500 mb-0">$ {item.quantity}</p>
            </div>
          </div>
        ))
      )}

      {/* <div className="screen:hidden" ref={componentRef}>
        <div className="grid grid-cols-3 gap-3">
          {tagData.map((item) =>
            item.brand?.map((brand) => (
              <div
                className="flex max-w-sm gap-3 border border-gray-100 px-3 py-3"
                style={{ backgroundColor: backgroundColor }}
                key={item.id}
              >
                <div className="w-full flex" key={brand.id}>
                  <div className="w-full flex mt-2">
                    <img className="w-20" src={`${baseURl}/file/f/${brand.image}`} />
                  </div>
                </div>
                <div className="w-full my-auto text-center">
                  <p className="mb-0" style={{ color: priceColor }}>
                    <span className="text-xl font-[1000] m-auto">$</span>{" "}
                    <span className="text-5xl font-[1000]"> {item.quantity}</span>
                  </p>
                  <p className="my-auto bg-slate-500 text-white p-1">{item.package.name}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div> */}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    <div className="w-full flex border space-x-1 rounded-md">
                      <button
                        onClick={() => setactiveTab(1)}
                        className={
                          activeTab === 1
                            ? "w-full text-md bg-blue-100 py-1.5 rounded-lg"
                            : "w-full text-md py-1.5 rounded-lg hover:bg-slate-300"
                        }
                      >
                        Basic
                      </button>
                      <button
                        onClick={() => setactiveTab(2)}
                        className={
                          activeTab === 2
                            ? "w-full text-md bg-blue-100 py-1.5 rounded-lg"
                            : "w-full text-md py-1.5 rounded-lg hover:bg-slate-300"
                        }
                      >
                        Advanced
                      </button>
                    </div>
                  </Dialog.Title>
                  {activeTab === 1 ? (
                    <>
                      <div className="w-full">
                        <div>
                          <p className="text-gray-500 mb-0">Project Name</p>
                        </div>
                        <input
                          value={projectName}
                          onChange={(e) => setprojectName(e.target.value)}
                          type="text"
                          className="w-full border-2 border-gray-200 outline-gray-300 py-1 px-2"
                        />
                      </div>
                      <div className="w-full mt-3">
                        <p className="text-gray-500 mb-0">Select the material from here</p>
                        <select
                          className="w-full border-2 border-gray-200 outline-gray-300 py-1 px-2 text-gray-500"
                          onChange={(e) => setdefaultMaterial(e.target.value)}
                          value={defaultMaterial}
                        >
                          <option>Materials</option>
                          {materials.map((item, index) => (
                            <option key={index} value={item.value}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="w-full mt-3">
                        <p className="text-gray-500 mb-0">Select the style from here</p>
                        <select
                          className="w-full border-2 border-gray-200 outline-gray-300 py-1 px-2 text-gray-500"
                          onChange={(e) => setdefaultStyle(e.target.value)}
                          value={defaultStyle}
                        >
                          <option>Styles</option>
                          {styles &&
                            styles.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      {defaultStyle === "ab-one" ? (
                        <AbOne backgroundColor={backgroundColor} priceColor={priceColor} />
                      ) : defaultStyle === "logo" ? (
                        <Logo backgroundColor={backgroundColor} priceColor={priceColor} />
                      ) : defaultStyle === "lnf-brewers" ? (
                        <Brewers backgroundColor={backgroundColor} priceColor={priceColor} />
                      ) : defaultStyle === "mitchell-gulfport" ? (
                        <MitchellGuld backgroundColor={backgroundColor} priceColor={priceColor} />
                      ) : defaultStyle === "pacific" ? (
                        <Pacific backgroundColor={backgroundColor} priceColor={priceColor} />
                      ) : defaultStyle === "big-foot-yellow" ? (
                        <BigFoot backgroundColor={backgroundColor} priceColor={priceColor} />
                      ) : defaultStyle === "cherokee-reward" ? (
                        <Cherokee backgroundColor={backgroundColor} priceColor={priceColor} />
                      ) : defaultStyle === "dean-upc" ? (
                        <DeanUpc backgroundColor={backgroundColor} priceColor={priceColor} />
                      ) : defaultStyle === "fahr-yellow-medium" ? (
                        <FahYellow backgroundColor={backgroundColor} priceColor={priceColor} />
                      ) : (
                        <div className="flex mt-4">
                          <div
                            className="flex gap-3 border border-gray-100 px-3 py-3"
                            style={{ backgroundColor: backgroundColor }}
                          >
                            <div className="w-full flex">
                              <img className="" src={require("../../../assets/instatags.png")} />
                            </div>
                            <div className="w-full my-auto text-center">
                              <p className="mb-0" style={{ color: priceColor }}>
                                <span className="text-xl font-[1000] m-auto">$</span>{" "}
                                <span className="text-5xl font-[1000]"> 25.99</span>
                              </p>
                              <p className="my-auto bg-slate-500 text-white p-1">4pk 16oz Cans</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="w-full mt-3">
                        <div className="flex">
                          <label>Default Background Color</label>
                          <div
                            className="w-8 h-8 ml-3 border-2"
                            style={{ background: backgroundColor }}
                          ></div>
                        </div>
                        <p className="text-gray-500 mb-0 text-sm">
                          Select background color from below:
                        </p>
                        <div className="bg-slate-100 p-3">
                          <CirclePicker
                            onChange={(e) => setbackgroundColor(e.hex)}
                            color={backgroundColor}
                            colors={colors}
                            width="100%"
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="w-full mt-3">
                        <div className="flex">
                          <label>Default Price Color</label>
                          <div
                            className="w-8 h-8 ml-3 border-2"
                            style={{ background: priceColor }}
                          ></div>
                        </div>
                        <p className="text-gray-500 mb-0 text-sm">Select price color from below:</p>
                        <div className="bg-slate-100 p-3">
                          <CirclePicker
                            className=""
                            onChange={(e) => setpriceColor(e.hex)}
                            color={priceColor}
                            colors={colors}
                            width="100%"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="w-full flex mt-3 justify-between">
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => deleteProjectFunc()}
                    >
                      Remove...
                    </button>
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => updateProjectFunc()}
                    >
                      Update
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={lineModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setlineModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    <div className="w-full flex border space-x-1 rounded-md">
                      <button
                        onClick={() => setactiveTab(1)}
                        className={
                          activeTab === 1
                            ? "w-full text-md bg-blue-100 py-1.5 rounded-lg"
                            : "w-full text-md py-1.5 rounded-lg hover:bg-slate-300"
                        }
                      >
                        General
                      </button>
                      <button
                        onClick={() => setactiveTab(2)}
                        className={
                          activeTab === 2
                            ? "w-full text-md bg-blue-100 py-1.5 rounded-lg"
                            : "w-full text-md py-1.5 rounded-lg hover:bg-slate-300"
                        }
                      >
                        Extras
                      </button>
                    </div>
                  </Dialog.Title>
                  {activeTab === 1 ? (
                    <>
                      <div className="w-full">
                        <p className="text-gray-500 mb-0">Select the brand from here</p>
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={brandData}
                          isMulti={true}
                        />
                      </div>
                      <div className="w-full mt-3">
                        <p className="text-gray-500 mb-0">Select the package from here</p>
                        <select
                          className="w-full border-2 border-gray-200 outline-gray-300 py-1.5 px-2 rounded-md text-gray-500"
                          onChange={(e) => setpackageId(e.target.value)}
                        >
                          <option>{linePackage}</option>
                          {packagesData.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="w-full mt-3">
                        <div>
                          <p className="text-gray-500 mb-0">Add amount here</p>
                        </div>
                        <input
                          value={lineQuantity}
                          onChange={(e) => setlineQuantity(e.target.value)}
                          type="text"
                          className="w-full border-2 border-gray-200 outline-gray-300 py-1.5 px-2 rounded-md"
                        />
                      </div>
                      <div className="w-full mt-3">
                        <div>
                          <p className="text-gray-500 mb-0">Add quantity here</p>
                        </div>
                        <input
                          value={lineCopies}
                          onChange={(e) => setlineCopies(e.target.value)}
                          type="number"
                          className="w-full border-2 border-gray-200 outline-gray-300 py-1.5 px-2 rounded-md"
                        />
                      </div>
                      <div className="w-full mt-3">
                        <div>
                          <p className="text-gray-500 mb-0">Alternate Text</p>
                        </div>
                        <input
                          value={alternateName}
                          onChange={(e) => setalternateName(e.target.value)}
                          type="text"
                          placeholder="Additional text..."
                          className="w-full border-2 border-gray-200 outline-gray-300 py-1.5 px-2 rounded-md"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-full mt-3">
                        <div className="flex">
                          <label>Default Background Color</label>
                          <div
                            className="w-8 h-8 ml-3 border-2"
                            style={{ background: backgroundColor }}
                          ></div>
                        </div>
                        <p className="text-gray-500 mb-0 text-sm">
                          Select background color from below:
                        </p>
                        <div className="bg-slate-100 p-3">
                          <CirclePicker
                            onChange={(e) => setbackgroundColor(e.hex)}
                            color={backgroundColor}
                            colors={colors}
                            width="100%"
                          />
                        </div>
                      </div>
                      <div className="w-full mt-3">
                        <div className="flex">
                          <label>Default Price Color</label>
                          <div
                            className="w-8 h-8 ml-3 border-2"
                            style={{ background: priceColor }}
                          ></div>
                        </div>
                        <p className="text-gray-500 mb-0 text-sm">Select price color from below:</p>
                        <div className="bg-slate-100 p-3">
                          <CirclePicker
                            className=""
                            onChange={(e) => setpriceColor(e.hex)}
                            color={priceColor}
                            colors={colors}
                            width="100%"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="w-full flex mt-3 justify-between">
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => deleteLineFunc()}
                    >
                      Remove...
                    </button>
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => updateProjectLineFunc()}
                    >
                      Update
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Projects;
