import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { createProject, getAllProjects } from "../../actions";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import moment from "moment";

const Start = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [projects, setprojects] = useState([]);
  const [name, setname] = useState("");
  const [isLoading, setisLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setisLoading(true);
    getAllProjects().then((res) => {
      debugger;
      if (res.status === 200) {
        setprojects(res.data);
        setisLoading(false);
      } else {
        setprojects([]);
      }
    });
  }, []);

  const createNewProject = () => {
    createProject(name).then((res) => {
      if (res.status == 201) {
        getAllProjects().then((res) => {
          debugger;
          if (res.status === 200) {
            setprojects(res.data);
            setisLoading(false);
          } else {
            setprojects([]);
          }
        });
        toast.success("Project Created Successfully!");
        setIsOpen(false);
      } else if (res.data.statusCode == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };

  console.log("projects", projects);
  return (
    <div className="container">
      {projects && projects.length > 0 ? (
        isLoading ? (
          <Spinner />
        ) : (
          <div>
            <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
              <h5>Projects</h5>
              <button
                className="bg-indigo-500 px-4 text-white rounded-sm"
                onClick={() => setIsOpen(true)}
              >
                New Project
              </button>
            </div>

            <div className="grid grid-cols-1 mt-3">
              <div className="">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Details</th>
                      <th scope="col">Last changed</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.map((item, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <Link className="no-underline" to={`/dashboard/projects/${item.id}`}>
                            <div className="flex">
                              <label className="mt-2 text-black cursor-pointer">{item.name}</label>
                            </div>
                          </Link>
                        </td>
                        <td className="">
                          <label className="text-gray-400 mb-0">{item.material}</label> <br />
                          <label className="text-gray-400 italic text-sm">{item.style}</label>
                        </td>
                        <td>{moment(item.updatedAt).format("MMM Do YY")}</td>
                        <td>
                          <div>
                            <Link to={`/dashboard/projects/${item.id}`}>
                              <label className="text-blue-500 font-bold cursor-pointer">Edit</label>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="grid grid-cols-1 justify-items-center justify-center text-center mt-4">
          <img className="w-52" src={require("../../assets/instatags.png")} />
          <br />
          <div className="bg-slate-200 text-center py-4 max-w-md w-full px-4">
            <h3>Welcome!</h3>
            <label className="text-xl">Let's make your first project:</label>
            <br />
            <button
              onClick={() => setIsOpen(true)}
              className="bg-indigo-500 text-white px-3 py-2 rounded-md mt-4"
            >
              Tag project
            </button>
            <br />
            <label className="text-slate-600 mt-2">
              Insta tag is a long established fact that distracted by the readable content of a page
              when looking at its layout.
            </label>
          </div>
        </div>
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Tag project
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Make shelf tags, simple case cards or statics. Even special stuff like retail
                      sheets and more.
                    </p>

                    <hr />
                    <div className="w-full">
                      <label className="font-bold">Name</label>
                      <input
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                        type="text"
                        placeholder="Name your tag project..."
                        className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => createNewProject()}
                    >
                      Create
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Start;
