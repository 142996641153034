import React from "react";
import { Route, Routes } from "react-router-dom";
import Activity from "./Activity/Activity";
import BrandDetails from "./Brands/BrandDetails";
import Brands from "./Brands/Brands";
import CustomerDetails from "./Customers/CustomerDetails";
import Customers from "./Customers/Customers";
import DashboardNavbar from "./DashboardNavbar";
import Distributors from "./Distributors/Distributors";
import PackageDetails from "./Packages/PackageDetails";
import Packages from "./Packages/Packages";
import Projects from "./Projects/Projects";
import Settings from "./Settings/Settings";
import Start from "./Start";
import UserDetails from "./Users/UserDetails";
import Users from "./Users/Users";
import AdminAllUsers from './AdminAllUsers/AdminAllUsers'

const Dashboard = (props) => {
  return (
    <DashboardNavbar>
      <Routes>
        <Route path="/start" element={<Start />} />
        <Route path="/Brands" element={<Brands />} />
        <Route path="/Brands" element={<Brands />} />
        <Route path="/BrandDetails/:id" element={<BrandDetails />} />
        <Route path="/Users" element={<Users />} />
        <Route path="/AdminAllUsers" element={<AdminAllUsers/>} />
        <Route path="/UserDetails/:id" element={<UserDetails />} />
        <Route path="/Packages" element={<Packages />} />
        <Route path="/PackageDetails/:id" element={<PackageDetails />} />
        <Route path="/Projects/:id" element={<Projects />} />
        <Route path="/Customers" element={<Customers />} />
        <Route path="/CustomerDetails/:id" element={<CustomerDetails />} />
        <Route path="/Activity" element={<Activity />} />
        <Route path="/Settings" element={<Settings />} />
        <Route path="/Distributors" element={<Distributors />} />
      </Routes>
    </DashboardNavbar>
  );
};

export default Dashboard;
