import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { MdOutlineMailOutline } from "react-icons/md";
import { getAllDistributors, updateDistributor } from "../../../actions";
import { toast } from "react-toastify";
import Spinner from "../../Common/Spinner";

const Distributors = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  const [brandData, setbrandData] = useState([]);

  useEffect(() => {
    getAllDistributors().then((res) => {
      debugger;
      setbrandData(res.data);
      setisLoading(false);
    });
  }, []);

  const updateStatus = (name, status, id, plan) => {
    updateDistributor(name, status, id, plan).then((res) => {
      debugger;
      if (res.status == 200) {
        toast.success("Status Updated Successfully!");
        getAllDistributors().then((res) => {
          debugger;
          setbrandData(res.data);
          setisLoading(false);
        });
        setisLoading(false);
      } else if (res.status == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };

  

  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5>Distributors</h5>
        {/* <button
          className="bg-indigo-500 px-4 text-white rounded-sm"
          onClick={() => setIsOpen(true)}
        >
          New User
        </button> */}
        
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 mt-3">
          <div className="">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Update Status</th>
                  <th scope="col">Account Type</th>
                  <th scope="col">Expiration</th>
                </tr>
              </thead>
              <tbody>
                {brandData.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}.</th>
                    <td></td>
                    <td>
                      {/* <Link className="no-underline" to={`/dashboard/UserDetails/${item.id}`}> */}
                      <div className="">
                        <label className="text-black cursor-pointer">{item.name}</label>
                      </div>
                      {/* </Link> */}
                    </td>

                    <td>
                      {item.status === "APPROVED" ? (
                        <label className="text-green-500">{item.status}</label>
                      ) : item.status === "PENDING" ? (
                        <label className="text-orange-300">{item.status}</label>
                      ) : item.status === "REJECTED" ? (
                        <label className="text-red-700">{item.status}</label>
                      ) : null}
                    </td>
                    <td>
                      <div>
                        <select
                          className="border-2 border-gray-200 outline-gray-300 py-1 px-1 text-gray-500"
                          onChange={(e) =>
                            updateStatus(item.name, e.target.value, item.id, item.plan)
                          }
                        >
                          <option value={item.status}>{item.status}</option>
                          <option value="PENDING">PENDING</option>
                          <option value="APPROVED">APPROVED</option>
                          <option value="REJECTED">REJECTED </option>
                          <option value="SUSPENDED">SUSPENDED</option>
                        </select>
                      </div>
                    </td>

                    <td>
                      <div>
                        <select
                          className="border-2 border-gray-200 outline-gray-300 py-1 px-1 text-gray-500"
                          // onChange={(e) =>
                          //   updateStatus(item.name, e.target.value, item.id, item.plan)
                          // }
                        >
                          {/* <option value={item.status}>{item.status}</option> */}
                          <option value="PENDING">YEARLY</option>
                          <option value="APPROVED">MONTHLY</option>
                          <option value="REJECTED">FREE</option>
                          <option value="SUSPENDED">TRIAL</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Invite User
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit
                      perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
                    </p>
                    <div className="w-full">
                      <input
                        type="email"
                        className="w-full border-2 border-gray-200 outline-gray-300"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Invite
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Distributors;
