import React from "react";

const Cherokee = (props) => {
  return (
    <div className="mt-4">
      <div
        className="w-100 flex gap-3 border border-gray-100"
        style={{ backgroundColor: props.backgroundColor }}
      >
        <div className="w-full mt-4 text-center">
          <img className="m-auto w-40 h-10" src={require("../../../assets/instatags.png")} />
          <p className="p-1 mt-4 font-bold" style={{ color: props.priceColor }}>
            4pk 16oz Cans
          </p>
        </div>
        <div className="w-full m-auto text-center">
          <div className=" bg-[#feff27]">
            <p className="mb-0 mt-3" style={{ color: props.priceColor }}>
              <span className="text-5xl font-[1000]">$25.99</span>
              <br />
              <span className="text-sm font-[1000]">LOYALTY CARD PRICE</span>
            </p>
          </div>
          <p className="m-aut0 p-1 font-bold" style={{ color: props.priceColor }}>
            Regular price: $5.99
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cherokee;
