import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const PrivacyPolicy = () => {
  // please upload the text from: http://v2.instatags.net/privacy.html
  return (
    <div class="mt-8">
      <Header />
      <div class="mx-auto max-w-7xl">
        <h1>Privacy Policy</h1>
        <p class="lead">
          Technical Maintenance Support respects individual privacy and values the confidence of its customers, employees,
          vendors, consumers, business partners and others. Technical Maintenance Support strives to collect, use and disclose
          Personal information in a manner consistent with the laws of the countries in which it does business, and has a
          tradition of upholding the highest ethical standards in its business practices. Technical Maintenance Support abides
          by the Safe Harbor Principles developed by the U.S. Department of Commerce and the European Commission and the
          Frequently Asked Questions (FAQ's) issued by the Department of Commerce on July 21, 2000. This Safe Harbor Privacy
          Policy (the "Policy") sets forth the privacy principles that Technical Maintenance Support follows with respect to
          transfers of Personal information anywhere in the world, including transfers from the European Economic Area (EEA)
          (which includes the twenty-five member states of the European Union (EU) plus Iceland, Liechtenstein and Norway) to
          the United States.
        </p>

        <h2>I. Safe Harbor</h2>
        <p>
          The United States Department of Commerce and the European Commission have agreed on a set of data protection principles
          and frequently asked questions (the "Safe Harbor Principles") to enable US companies to satisfy the requirement under
          European Union law that adequate protection be given to Personal information transferred from the EU to the United States.
          The EEA also has recognized the US Safe Harbor as providing adequate data protection (OJ L 45, 15.2.2001, p.47).
          Consistent with its commitment to protect Personal privacy, Technical Maintenance Support adheres to the Safe Harbor
          Principles. Technical Maintenance Support has a Global Director of Data Protection who assists in ensuring compliance
          with this Policy and data security issues. Technical Maintenance Support educates its employees concerning compliance
          with this Policy and has self-assessment procedures in place to assure compliance. Technical Maintenance Support's
          Global Director of Data Protection William H. Murray and its Corporate Legal Team are available to any of its valued
          employees, customers, vendors, business partners or others who may have questions concerning this Policy or data
          security practices. Relevant contact information is provided herein.
        </p>

        <h2>II. Scope</h2>
        <p>
          This Policy applies to all Personal information received by Technical Maintenance Support in any format including
          electronic, paper or verbal. Technical Maintenance Support collects and processes Personal information concerning
          current and former employees and their respective family members, as well as applicants for employment through its
          Internet websites, its intranet site, electronic mail and manually. Technical Maintenance Support is the sole owner
          of information it collects from current and former employees, applicants for employment, customers, vendors and
          others. Technical Maintenance Support will not sell or share this information with third parties in ways different
          than what is disclosed in this Privacy Policy. On a global basis, Technical Maintenance Support will, and will
          cause its affiliates to, establish and maintain business procedures that are consistent with this Policy. Personal
          information collected by Technical Maintenance Support from employees and applicants for employment is maintained
          at its corporate offices in Foothill Ranch, California in the United States as well as the local office of the
          employee or applicant. Technical Maintenance Support collects Personal information for, among other things,
          legitimate human resource business reasons such as payroll administration; filling employment positions;
          administration and operations of its benefit programs; meeting governmental reporting requirements; security,
          health and safety management; performance management; company network access; and authentication.
          Technical Maintenance Support does not request or gather information regarding political opinions, religion,
          philosophy or sexual preference. To the extent Technical Maintenance Support maintains information on an
          individual's medical health or ethnicity (as legally required), Technical Maintenance Support will protect, secure
          and use that information in a manner consistent with this Policy and applicable law. Personal information collected
          by Technical Maintenance Support from prospective customers, consumers, vendors, business partners and others may
          be maintained at its corporate offices in Foothill Ranch, California or at other Technical Maintenance Support
          facilities. Technical Maintenance Support collects Personal information for, among other things, legitimate
          business reasons such as customer service; product, warranty and claims administration; meeting governmental
          reporting and records requirements; maintenance of accurate accounts payable and receivable records; internal
          marketing research; safety and performance management; financial and sales data; and contact information. All
          Personal information collected by Technical Maintenance Support will be used for legitimate business purposes
          consistent with this Policy.
        </p>

        <h2>III. Definitions</h2>
        <p>
          For purposes of this Policy, the following definitions shall apply: "Agent" means any third party that uses Personal
          information provided by Technical Maintenance Support to perform tasks on behalf of or at the instruction of
          Technical Maintenance Support. "Technical Maintenance Support" means Technical Maintenance Support, its predecessors,
          successors, subsidiaries, divisions and groups. "Personal information" means any information or set of information
          that identifies or could be used by or on behalf of Technical Maintenance Support to identify an individual.
          Personal information does not include information that is encoded or anonymized, or publicly available information
          that has not been combined with non-public Personal information. "Sensitive Personal information" means Personal
          information that reveals race, ethnic origin, trade union membership, or that concerns health. In addition, Technical
          Maintenance Support will treat as sensitive Personal information any information received from a third party where
          that third party treats and identifies the information as sensitive.
        </p>

        <h2>IV. Privacy Principles</h2>
        <p>
          The privacy principles in this Policy are based on the seven Safe Harbor Principles.
        </p>
        <ol style={{listStyleType: "decimal", listStylePosition: "outside"}}>
        <li>
          <i>NOTICE:</i> Where Technical Maintenance Support collects Personal information directly from individuals, it
          will inform them about the purposes for which it collects and uses Personal information about them, the types
          of non-agent third parties to which Technical Maintenance Support discloses that information, and the choices
          and means, if any, Technical Maintenance Support offers individuals for limiting the use and disclosure of their
          Personal information. Notice will be provided in clear and conspicuous language when individuals are first asked
          to provide Personal information to Technical Maintenance Support, or as soon as practicable thereafter, and in
          any event before Technical Maintenance Support uses the information for a purpose other than that for which it
          was originally collected. Technical Maintenance Support may disclose Personal information if required to do so
          by law or to protect and defend the rights or property of Technical Maintenance Support.
        </li>
        <li>
          <i>CHOICE:</i> Technical Maintenance Support will offer individuals the opportunity to choose (opt-out) whether
          their Personal information is (a) to be disclosed to a non-agent third party, or (b) to be used for a purpose
          other than the purpose for which it was originally collected or subsequently authorized by the individual. For
          sensitive Personal information, Technical Maintenance Support will give individuals the opportunity to
          affirmatively and explicitly (opt-in) consent to the disclosure of the information to a non-agent third party
          or the use of the information for a purpose other than the purpose for which it was originally collected or
          subsequently authorized by the individual. Technical Maintenance Support will provide individuals with
          reasonable mechanisms to exercise their choices should requisite circumstances arise.
        </li>
        <li>
          <i>DATA INTEGRITY:</i> Technical Maintenance Support will use Personal information only in ways that are
          compatible with the purposes for which it was collected or subsequently authorized by the individual. Technical
          Maintenance Support will take reasonable steps to ensure that Personal information is relevant to its intended
          use, accurate, complete and current.
        </li>
        <li>
          <i>TRANSFERS TO AGENTS:</i> Technical Maintenance Support will obtain assurances from its Agents that they will
          safeguard Personal information consistently with this Policy. Examples of appropriate assurances that may be
          provided by Agents include: a contract obligating the Agent to provide at least the same level of protection
          as is required by the relevant Safe Harbor Principles, being subject to EU Directive 95/46/EC (the EU Data
          Protection Directive), Safe Harbor certification by the Agent, or being subject to another European Commission
          adequacy finding (e.g., companies located in Switzerland). Where Technical Maintenance Support has knowledge
          that an Agent is using or disclosing Personal information in a manner contrary to this Policy, Technical
          Maintenance Support will take reasonable steps to prevent or stop the use or disclosure. Technical Maintenance
          Support holds it Agents accountable for maintaining the trust our employees and customers place in the company.
        </li>
        <li>
          <i>ACCESS AND CORRECTION:</i> Upon request, Technical Maintenance Support will grant individuals reasonable access
          to Personal information that it holds about them. In addition, Technical Maintenance Support will take reasonable
          steps to permit individuals to correct, amend or delete information that is demonstrated to be inaccurate or
          incomplete. Any employees that desire to review or update their Personal information can do so by contacting
          their local Human Resources Representative.
        </li>
        <li>
          <i>SECURITY:</i> Technical Maintenance Support will take reasonable precautions to protect Personal information
          in its possession from loss, misuse and unauthorized access, disclosure, alteration and destruction. Technical
          Maintenance Support protects data in many ways. Physical security is designed to prevent unauthorized access to
          database equipment and hard copies of sensitive Personal information. Electronic security measures continuously
          monitor access to our servers and provide protection from hacking or other unauthorized access from remote
          locations. This protection includes the use of firewalls, restricted access and encryption technology. Technical
          Maintenance Support limits access to Personal information and data to those persons in Technical Maintenance
          Support's organization, or as agents of Technical Maintenance Support, that have a specific business purpose
          for maintaining and processing such Personal information and data. Individuals who have been granted access to
          Personal information are aware of their responsibilities to protect the security, confidentiality and integrity
          of that information and have been provided training and instruction on how to do so.
        </li>
        <li>
          <i>ENFORCEMENT:</i> Technical Maintenance Support will conduct compliance audits of its relevant privacy practices
          to verify adherence to this Policy and the US Department of Commerce Safe Harbor Principles. Any employee that
          Technical Maintenance Support determines is in violation of this Policy will be subject to disciplinary action
          up to and including termination of employment.
        </li>
        </ol>

        <h2>V. Dispute Resolution</h2>
        <p>
          Any questions or concerns regarding the use or disclosure of Personal information should be directed to the Technical
          Maintenance Support Privacy Office at the address given below. Technical Maintenance Support will investigate and
          attempt to resolve complaints and disputes regarding use and disclosure of Personal information in accordance with
          the principles contained in this Policy. For complaints that cannot be resolved between Technical Maintenance
          Support and the complainant, Technical Maintenance Support has agreed to participate in the dispute resolution
          procedures of the panel established by the European data protection authorities to resolve disputes pursuant to the
          Safe Harbor Principles.
        </p>

        <h2>VI. Internet Privacy</h2>
        <p>
          Technical Maintenance Support sees the Internet, intranets and the use of other technologies as valuable tools for
          communicating and interacting with consumers, employees, vendors, business partners and others. Technical Maintenance
          Support recognizes the importance of maintaining the privacy of Personal information collected through websites
          that it operates. Technical Maintenance Support's sole purpose for operating its websites is to provide information
          concerning products and services to the public. In general, visitors can reach Technical Maintenance Support on the
          Web without revealing any Personal information. Visitors on the Web may elect to voluntarily provide Personal
          information via Technical Maintenance Support websites but are not required to do so. Technical Maintenance Support
          collects information from visitors to the websites who voluntarily provide Personal information by filling out and
          submitting online questionnaires concerning feedback on the website, requesting information on products or services,
          or seeking employment. The Personal information voluntarily provided by website users is contact information limited
          to the user's name, home and/or business address, phone numbers and email address. Technical Maintenance Support
          collects this information so it may answer questions and forward requested information. Technical Maintenance
          Support does not sell or share this information with non-agent third parties. Technical Maintenance Support may
          also collect anonymous information concerning website users through the use of "cookies" in order to provide better
          customer service. "Cookies" are small files that websites place on users' computers to identify the user and enhance
          the website experience. None of this information is reviewed at an individual level. Visitors may set their browsers
          to provide notice before they receive a cookie, giving the opportunity to decide whether to accept the cookie.
          Visitors can also set their browsers to turn off cookies. If visitors do so, however, some areas of Technical Maintenance
          Support websites may not function properly. Few, if any, of Technical Maintenance Support's websites are directed
          toward children. Nevertheless, Technical Maintenance Support is committed to complying with applicable laws and
          requirements, such as the United States' Children's Online Privacy Protection Act ("COPPA"). Technical Maintenance
          Support website users have the option to request that Technical Maintenance Support not use information previously
          provided, correct information previously provided, or remove information previously provided to Technical Maintenance
          Support. Those that would like to correct or suppress information they have provided to Technical Maintenance
          Support should forward such inquiries to:
        </p>
        <div class="mb-3">
          <b>TMS Inc.</b> <br/>
          26845 Vista Terrace, <br/>
          Lake Forest, CA 92630
        </div>
        <p>
          The inquiries should include the individual's name, address, and other relevant contact information (phone number,
          email address). Technical Maintenance Support will use all reasonable efforts to honor such requests as quickly as
          possible. Technical Maintenance Support websites may contain links to other "non-Technical Maintenance Support"
          websites. Technical Maintenance Support assumes no responsibility for the content or the privacy policies and
          practices on those websites. Technical Maintenance Support encourages all users to read the privacy statements of
          those sites; their privacy practices may differ from those of Technical Maintenance Support.
        </p>

        <h2>VII. Changes To This Safe Harbor Privacy Policy</h2>
        <p>
          The practices described in this Policy are current Personal data protection policies as of February 21, 2006.
          Technical Maintenance Support reserves the right to modify or amend this Policy at any time consistent with the
          requirements of the Safe Harbor Principles. Appropriate public notice will be given concerning such amendments.
        </p>
      </div>  
     <Footer />
    </div>
  )
};

export default PrivacyPolicy;
