import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { MdOutlineMailOutline } from "react-icons/md";
import { exportBrand, getAllUsers, importUsers, sendUserInvite } from "../../../actions";
import Spinner from "../../Common/Spinner";
import { toast } from "react-toastify";
import emptyImg from "../../../assets/empty.svg";
import { FaFileCsv } from "react-icons/fa";
import { baseURl } from "../../../utlilities";
import { getAllDistributors, updateDistributor, getAdminDistributorUsers } from "../../../actions";
import { format } from 'date-fns'
import moment from 'moment'
import {
  createProjectTag,
  deleteProject,
  deleteProjectLine,
  getAllBands,
  getAllMaterials,
  getAllPackages,
  getAllStyles,
  getProjectTag,
  getSingleProject,
  makeProject,
  updateProject,
  updateProjectLine,
} from "../../../actions";
const AdminAllUsers = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [userEmail, setuserEmail] = useState("");
  const [brandData, setbrandData] = useState([]);
  const [searchBox, setsearchBox] = useState("");
  const [selectedFile, setselectedFile] = useState(null);
  const [openImport, setopenImport] = useState(false);
  const [packagesData, setpackagesData] = useState([]);
  const [getdistributorsusersid, setgetdistributorsusersid] = useState('');
  const [getdistributorsusers, setgetdistributorsusers] = useState([])




  useEffect(() => {
    getAllDistributors().then((res) => {
      debugger;
      setbrandData(res.data);
    });
  }, [searchBox]);

  useEffect(() => {
  
    getAllUsers(searchBox).then((res) => {
      setgetdistributorsusers(res.data);
      // setisLoading(false);
    });
  }, [searchBox]);


  useEffect(() => {
    if (getdistributorsusersid !== '') {
      getAdminDistributorUsers(getdistributorsusersid).then((res) => {
        debugger;
        setgetdistributorsusers(res.data);
        setisLoading(false);
      });
    }

  }, [getdistributorsusersid]);




  const imortBrandFunc = () => {
    setisLoading(true);
    importUsers(selectedFile).then((res) => {
      if (res.status == 201) {
        toast.success("File Imported Successfully!");
        setopenImport(false);
        getAllUsers(searchBox).then((res) => {
          setbrandData(res.data);
          setisLoading(false);
        });
      } else if (res.data.statusCode == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
      debugger;
    });
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove()
  };

  const exportbrandFunc = () => {
    // Headers for each column
    let headers = ["Id,Name,Email"];

    // Convert users data to a csv
    let usersCsv = brandData.reduce((acc, user) => {
      const { id, name, email } = user;
      acc.push([id, name, email].join(","));
      return acc;
    }, []);

    downloadFile({
      data: [...headers, ...usersCsv].join("\n"),
      fileName: "UsersData.csv",
      fileType: "text/csv",
    });
  };

  const sendUserEmail = () => {
    if (userEmail) {
      let name = userEmail.substring(0, userEmail.indexOf("@"));
      sendUserInvite(name, userEmail, "MEMBER").then((res) => {
        if (res.status == 201) {
          getAllUsers(searchBox).then((res) => {
            setbrandData(res.data);
            setisLoading(false);
          });
          toast.success("User Invited Successfully!");
          setIsOpen(false);
        } else if (res.data.statusCode == 404) {
          toast.error("Error!");
          setIsOpen(false);
          debugger;
        } else {
          setIsOpen(false);
          toast.error("Error!");
        }
        debugger;
      });
    } else {
      toast.error("Email Should not be empty");
    }
  };
  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5 className="">Users</h5>
        <div className="flex gap-4">
          <div className="">
            <input
              placeholder="Search users..."
              type="text"
              className="w-80 border-2 border-gray-200 outline-gray-300 px-1 py-1"

              onChange={(e) => setsearchBox(e.target.value)}
              value={searchBox}
            />
          </div>

          <div className="">
            <select
              className="w-full border-2 border-gray-200 outline-gray-300 py-1.5 px-2 rounded-md"
              onChange={(e) => setgetdistributorsusersid(e.target.value)}
            >
             {isLoading === true &&  <option>Distributors</option> }
              {brandData && brandData.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          {/* <div className="">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="bg-blue-200 inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  Import/Export <FaFileCsv className="mt-1 ml-2" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => setopenImport(true)}
                        >
                          Import
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          onClick={() => exportbrandFunc()}
                        >
                          Export
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div> */}
          <div>
            <button
              className="mx-auto bg-indigo-600 px-4 py-2 text-white rounded-md"
            //   onClick={() => setIsOpen(true)}
            >
              New User
            </button>
          </div>
        </div>
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 mt-3">
          <div className="">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                  <th scope="col">Last Seen</th>
                  <th scope="col">Labels</th>
                  <th scope="col">Routes</th>
                  <th scope="col">Roles</th>
                  {/* <th scope="col">Projects</th> */}
                </tr>
              </thead>
              <tbody>


                {isLoading === false && getdistributorsusers && getdistributorsusers.map((item, index) => (

                  <tr key={index}>
                    <td scope="col"></td>
                    <td>
                      <div className="flex">
                        <div>
                          {item.image ? (
                            <img
                              className="w-16 h-16 mr-2"
                              src={`${baseURl}/file/f/${item.image}`}
                            />
                          ) : (
                            <img className="w-16 mr-2" src={emptyImg} />
                          )}
                        </div>
                        <div>
                          {item.status === "PENDING" ? (
                            <span className="italic text-gray-500">Waiting to confirm invite</span>
                          ) : (
                            <Link className="no-underline" to={`/dashboard/UserDetails/${item.id}`}>
                              <span> {item.name} </span>
                            </Link>
                          )}
                          <br />
                          <label className="text-gray-400">{item.email}</label>
                        </div>
                      </div>
                    </td>
                    {/* <td scope="col">{item.lastSeen}</td> */}
                    {item.lastSeen === null ?
                    <td scope="col">{item.lastSeen}</td>:
                    <td scope="col">{moment(item.lastSeen).format('DD/MM/YYYY HH:mm')}</td>  
                  }
                    <td scope="col">{item.label}</td>
                    <td scope="col">{item.route}</td>
                    <td scope="col">{item.role}</td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>
      )}

    </div>
  );
};

export default AdminAllUsers;
