import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { register } from "../actions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [confirmEmail, setconfirmEmail] = useState("");
  const [password, setpassword] = useState("");
  const [number, setnumber] = useState("");
  const [distributor, setdistributor] = useState("");

  const navigate = useNavigate();

  const registerUser = async () => {
    register(name, email, number, password, distributor)
      .then((res) => {
        if (res.statusText == "Created") {
          toast.success("Account Created Successfully!");
          navigate("/login");
          localStorage.setItem("accessToken", res.data.accessToken);
        } else if (res.data.statusCode === 403) {
          toast.success("Account Created Successfully!");
          navigate("/login");
          debugger;
        } else if (res.data.statusCode !== 403) {
          toast.error(res.data.message);
          debugger;
        } else {
          toast.error("Error!");
        }
      })
      .catch((err) => {
        debugger;
      });
  };

  return (
    <div>
      <Header />
      <div className="container pb-20 flex">
        <div className="w-full max-w-md mb-10">
          <h2 className="font-bold tracking-tight text-indigo-600 sm:text-5xl md:text-4xl">
            Sign up
          </h2>
          <div className="space-y-5">
            <div className="mt-10">
              <label for="first-name" class="block text-sm font-medium text-gray-700">
                Full Name
              </label>
              <input
                onChange={(e) => setname(e.target.value)}
                value={name}
                type="text"
                name="first-name"
                id="first-name"
                autocomplete="given-name"
                className="mt-1 block w-full py-2 rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="">
              <label for="first-name" class="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                onChange={(e) => setemail(e.target.value)}
                value={email}
                type="text"
                name="first-name"
                id="first-name"
                autocomplete="given-name"
                className="mt-1 block w-full py-2 rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="">
              <label for="first-name" class="block text-sm font-medium text-gray-700">
                Confirm Email
              </label>
              <input
                onChange={(e) => setconfirmEmail(e.target.value)}
                value={confirmEmail}
                type="text"
                name="first-name"
                id="first-name"
                autocomplete="given-name"
                className="mt-1 block w-full py-2 rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="">
              <label for="first-name" class="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                onChange={(e) => setpassword(e.target.value)}
                value={password}
                type="password"
                name="first-name"
                id="first-name"
                autocomplete="given-name"
                className="mt-1 block w-full py-2 rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="mt-4">
              <label for="first-name" class="block text-sm font-medium text-gray-700">
                Contact number
              </label>
              <input
                onChange={(e) => setnumber(e.target.value)}
                value={number}
                type="text"
                name="first-name"
                id="first-name"
                autocomplete="given-name"
                className="mt-1 block w-full py-2 rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div className="mt-4">
              <label for="first-name" class="block text-sm font-medium text-gray-700">
                Your distributor name
              </label>
              <input
                onChange={(e) => setdistributor(e.target.value)}
                value={distributor}
                type="text"
                name="first-name"
                id="first-name"
                autocomplete="given-name"
                className="mt-1 block w-full py-2 rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <button
                onClick={() => registerUser()}
                className="w-full bg-indigo-500 p-2.5 text-white rounded hover:bg-indigo-600"
              >
                Sign up
              </button>
            </div>
            <div className="text-center">
              <label className="text-slate-700">OR</label>
            </div>
            <div>
              <button className="w-full bg-blue-500 p-2.5 text-white rounded hover:bg-blue-600">
                <div className="text-center d-flex justify-center">
                  <FaFacebook className="text-2xl mr-2" /> Sign up with Facebook
                </div>
              </button>
            </div>
            <div>
              <button className="w-full bg-slate-200 p-2.5 rounded hover:bg-slate-300">
                <div className="text-center d-flex justify-center">
                  <FcGoogle className="text-2xl mr-2" /> Sign up with Google
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="hidden text-center justify-center lg:flex">
          <img className="text-center w-3/4 h-3/4" src={require("../assets/Mobilelogin.png")} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignUp;
