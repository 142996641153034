import React from "react";

const Logo = (props) => {
  return (
    <div>
      <div className="flex mt-4">
        <div
          className="flex gap-3 border border-gray-100 px-3 py-3"
          style={{ backgroundColor: props.backgroundColor }}
        >
          <div className="w-full flex">
            <img className="" src={require("../../../assets/instatags.png")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logo;
