import React from "react";

const BigFoot = (props) => {
  return (
    <div>
      <div className="flex mt-4">
        <div
          className="w-100 flex gap-3 border border-gray-100"
          style={{ backgroundColor: props.backgroundColor }}
        >
          <div className="flex">
            <img className="m-auto w-40 h-10" src={require("../../../assets/instatags.png")} />
          </div>
          <div className="w-full m-auto text-center bg-[#feff27]">
            <p className="mb-0 mt-3" style={{ color: props.priceColor }}>
              <span className="text-4xl relative -top-5 font-[1000] m-auto">$</span>
              <span className="text-6xl font-[1000]">25.99</span>
              <span className="text-sm">+DEPOSIT</span>
            </p>
            <p className="m-aut0 p-1 text-2xl" style={{ color: props.priceColor }}>
              4pk 16oz Cans
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigFoot;
