import React, { useEffect, useState } from "react";
import {
  fileUpload,
  fileUploadAfterSignURL,
  getAllMaterials,
  getAllMaterialsDefined,
  getAllStyles,
  getAllStylesDefined,
  getSingleDistributorDetails,
  updateSingleDistributor,
} from "../../../actions";
import { toast } from "react-toastify";
import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css";
import { baseURl } from "../../../utlilities";

const Settings = () => {
  const [name, setname] = useState("");
  const [imageId, setimageId] = useState("");
  const [status, setstatus] = useState("");
  const [selectedImage, setselectedImage] = useState(null);
  const [distributorId, setdistributorId] = useState("");
  const [motto, setmotto] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [timeZone, settimeZone] = useState("");
  const [tags, setTags] = React.useState([]);
  const [activeTab, setactiveTab] = useState(1);
  const [selectedMaterial, setselectedMaterial] = useState([]);
  const [selectedStyle, setselectedStyle] = useState([]);
  const [materials, setmaterials] = useState([]);
  const [styles, setstyles] = useState([]);
  const [defaultMaterial, setdefaultMaterial] = useState("");
  const [defaultStyle, setdefaultStyle] = useState(null);
  const [materialsArray, setmaterialsArray] = useState([]);
  const [stylesArray, setstylesArray] = useState([]);

  useEffect(() => {
    const distributor = JSON.parse(localStorage.getItem("userDetails"));
    getSingleDistributorDetails(distributor.distributorId).then((res) => {
      setname(res.data.name);
      setimageId(res.data.image);
      setstatus(res.data.status);
      setdistributorId(res.data.id);
      setmotto(res.data.motto);
      settimeZone(res.data.timezone);
      setTags(res.data.label === null ? [] : res.data.label);
      setselectedMaterial(res.data.selectedMaterial === null ? [] : res.data.selectedMaterial);
      setselectedStyle(res.data.selectedStyle === null ? [] : res.data.selectedStyle);
      setdefaultMaterial(res.data.defaultMaterial);
      setdefaultStyle(res.data.defaultStyle);
      debugger;
    });

    getAllMaterials(distributor.distributorId).then((res) => {
      debugger;
      setmaterials(res.data);
    });

    getAllStyles(distributor.distributorId).then((res) => {
      debugger;
      setstyles(res.data);
    });

    getAllMaterialsDefined().then((res) => {
      debugger;
      const material = Object.keys(res.data).map((item) => {
        return {
          name: item,
          value: res.data[item],
        };
      });
      setmaterialsArray(material);
    });

    getAllStylesDefined().then((res) => {
      debugger;
      const styles = Object.keys(res.data).map((item) => {
        return {
          name: item,
          value: res.data[item],
        };
      });
      setstylesArray(styles);
    });
  }, []);

  const uploadImage = () => {
    fileUpload(selectedImage.name).then((res) => {
      setimageId(res.data.id);
      fileUploadAfterSignURL(res.data.signUrl, selectedImage).then((res2) => {
        toast.success("Image Uploaded Successfully!");
        debugger;
      });
      debugger;
    });
  };

  const saveUserDetails = () => {
    setisLoading(true);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    updateSingleDistributor(
      name,
      status,
      distributorId,
      "FREE",
      imageId,
      motto,
      timeZone,
      tags,
      defaultMaterial,
      selectedMaterial,
      defaultStyle,
      selectedStyle
    ).then((res) => {
      if (res.status == 200) {
        setisLoading(false);
        toast.success("Information Updated Successfully!");
      } else if (res.status == 404) {
        setisLoading(false);
        toast.error("Error!");
        debugger;
      } else {
        setisLoading(false);
        toast.error("Error!");
      }
    });
  };

  const changeMaterials = (data) => {
    debugger;
    if (selectedMaterial.find((obj) => obj.name === data.name)) {
      debugger;
      setselectedMaterial(selectedMaterial.filter((item) => item.name !== data.name));
    } else {
      setselectedMaterial([...selectedMaterial, data]);
    }
  };
  const changeStyles = (data) => {
    if (selectedStyle.find((obj) => obj.name === data.name)) {
      setselectedStyle(selectedStyle.filter((item) => item.name !== data.name));
    } else {
      setselectedStyle([...selectedStyle, data]);
    }
  };
  console.log("API Materials", materialsArray);
  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5>Company Settings &gt; </h5>
        <button
          disabled={isLoading}
          className="px-2 py-1 bg-indigo-500 text-white disabled:opacity-75"
          onClick={() => saveUserDetails()}
        >
          Save
        </button>
      </div>

      <div className="flex mt-3 gap-4 mb-5">
        <div className="w-72 bg-gray-50 px-4 py-3 shadow-md">
          <div
            onClick={() => setactiveTab(1)}
            className={
              activeTab === 1
                ? "bg-blue-200 font-bold px-2 py-1 rounded-md cursor-pointer"
                : "hover:bg-blue-200 px-2 py-1 rounded-md cursor-pointer "
            }
          >
            <label className="">General</label>
          </div>
          <hr />
          <div
            onClick={() => setactiveTab(2)}
            className={
              activeTab === 2
                ? "bg-blue-200 font-bold px-2 py-1 rounded-md cursor-pointer"
                : "hover:bg-blue-200 px-2 py-1 rounded-md cursor-pointer "
            }
          >
            <label className="cursor-pointer text-sm">Tags</label>
          </div>
        </div>
        {activeTab === 1 ? (
          <div className="w-full border border-gray-300 px-3 py-3">
            <div className="flex justify-between">
              <h5>General</h5>
            </div>
            <hr />
            <div className="grid grid-cols-5 space-y-5">
              <div className="">
                <label>Company logo</label>
              </div>
              <div className="col-span-4">
                {selectedImage && imageId !== "" ? (
                  <img className="w-32 h-32 mb-3" src={URL.createObjectURL(selectedImage)} />
                ) : (
                  <img className="w-32 h-32 mb-3" src={`${baseURl}/file/f/${imageId}`} />
                )}
                <input
                  type="file"
                  name="myImage"
                  onChange={(event) => {
                    console.log(event.target.files);
                    setselectedImage(event.target.files[0]);
                  }}
                />
                <br />
                <button onClick={() => uploadImage()} className="px-2 py-1 bg-slate-100 mt-4">
                  Add company logo
                </button>
              </div>
              <div className="">
                <label>Company name</label>
              </div>
              <div className="col-span-4">
                <input
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  type="text"
                  className="w-full max-w-sm border-2 border-gray-200 outline-gray-300 py-1 px-2"
                />
              </div>
              <div className="">
                <label>Company motto</label>
              </div>
              <div className="col-span-4">
                <input
                  value={motto}
                  onChange={(e) => setmotto(e.target.value)}
                  type="text"
                  className="w-full max-w-sm border-2 border-gray-200 outline-gray-300 py-1 px-2"
                />
                <p className="text-sm text-gray-400 mb-0">
                  An inspiring quote that signifies the greatness of your company
                </p>
              </div>
              <div className="">
                <label>Time Zone</label>
              </div>
              <div className="col-span-4">
                <select
                  value={timeZone}
                  className="w-full max-w-sm border-2 border-gray-200 outline-gray-300 py-1 px-2 text-gray-500"
                  onChange={(e) => settimeZone(e.target.value)}
                >
                  <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                  <option value="America/Adak">(GMT-10:00) Hawaii-Aleutian</option>
                  <option value="Etc/GMT+10">(GMT-10:00) Hawaii</option>
                  <option value="Pacific/Marquesas">(GMT-09:30) Marquesas Islands</option>
                  <option value="Pacific/Gambier">(GMT-09:00) Gambier Islands</option>
                  <option value="America/Anchorage">(GMT-09:00) Alaska</option>
                  <option value="America/Ensenada">(GMT-08:00) Tijuana, Baja California</option>
                  <option value="Etc/GMT+8">(GMT-08:00) Pitcairn Islands</option>
                  <option value="America/Los_Angeles">
                    (GMT-08:00) Pacific Time (US & Canada)
                  </option>
                  <option value="America/Denver">(GMT-07:00) Mountain Time (US & Canada)</option>
                  <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                  <option value="America/Dawson_Creek">(GMT-07:00) Arizona</option>
                  <option value="America/Belize">(GMT-06:00) Saskatchewan, Central America</option>
                  <option value="America/Cancun">
                    (GMT-06:00) Guadalajara, Mexico City, Monterrey
                  </option>
                  <option value="Chile/EasterIsland">(GMT-06:00) Easter Island</option>
                  <option value="America/Chicago">(GMT-06:00) Central Time (US & Canada)</option>
                  <option value="America/New_York">(GMT-05:00) Eastern Time (US & Canada)</option>
                  <option value="America/Havana">(GMT-05:00) Cuba</option>
                  <option value="America/Bogota">
                    (GMT-05:00) Bogota, Lima, Quito, Rio Branco
                  </option>
                  <option value="America/Caracas">(GMT-04:30) Caracas</option>
                  <option value="America/Santiago">(GMT-04:00) Santiago</option>
                  <option value="America/La_Paz">(GMT-04:00) La Paz</option>
                  <option value="Atlantic/Stanley">(GMT-04:00) Faukland Islands</option>
                  <option value="America/Campo_Grande">(GMT-04:00) Brazil</option>
                  <option value="America/Goose_Bay">(GMT-04:00) Atlantic Time (Goose Bay)</option>
                  <option value="America/Glace_Bay">(GMT-04:00) Atlantic Time (Canada)</option>
                  <option value="America/St_Johns">(GMT-03:30) Newfoundland</option>
                  <option value="America/Araguaina">(GMT-03:00) UTC-3</option>
                  <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                  <option value="America/Miquelon">(GMT-03:00) Miquelon, St. Pierre</option>
                  <option value="America/Godthab">(GMT-03:00) Greenland</option>
                  <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires</option>
                  <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                  <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                  <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                  <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                  <option value="Europe/Belfast">(GMT) Greenwich Mean Time : Belfast</option>
                  <option value="Europe/Dublin">(GMT) Greenwich Mean Time : Dublin</option>
                  <option value="Europe/Lisbon">(GMT) Greenwich Mean Time : Lisbon</option>
                  <option value="Europe/London">(GMT) Greenwich Mean Time : London</option>
                  <option value="Africa/Abidjan">(GMT) Monrovia, Reykjavik</option>
                  <option value="Europe/Amsterdam">
                    (GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna
                  </option>
                  <option value="Europe/Belgrade">
                    (GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague
                  </option>
                  <option value="Europe/Brussels">
                    (GMT+01:00) Brussels, Copenhagen, Madrid, Paris
                  </option>
                  <option value="Africa/Algiers">(GMT+01:00) West Central Africa</option>
                  <option value="Africa/Windhoek">(GMT+01:00) Windhoek</option>
                  <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                  <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                  <option value="Asia/Gaza">(GMT+02:00) Gaza</option>
                  <option value="Africa/Blantyre">(GMT+02:00) Harare, Pretoria</option>
                  <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                  <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                  <option value="Asia/Damascus">(GMT+02:00) Syria</option>
                  <option value="Europe/Moscow">
                    (GMT+03:00) Moscow, St. Petersburg, Volgograd
                  </option>
                  <option value="Africa/Addis_Ababa">(GMT+03:00) Nairobi</option>
                  <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                  <option value="Asia/Dubai">(GMT+04:00) Abu Dhabi, Muscat</option>
                  <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                  <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                  <option value="Asia/Yekaterinburg">(GMT+05:00) Ekaterinburg</option>
                  <option value="Asia/Tashkent">(GMT+05:00) Tashkent</option>
                  <option value="Asia/Kolkata">
                    (GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi
                  </option>
                  <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                  <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                  <option value="Asia/Novosibirsk">(GMT+06:00) Novosibirsk</option>
                  <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                  <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                  <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                  <option value="Asia/Hong_Kong">
                    (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi
                  </option>
                  <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                  <option value="Australia/Perth">(GMT+08:00) Perth</option>
                  <option value="Australia/Eucla">(GMT+08:45) Eucla</option>
                  <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                  <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                  <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                  <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                  <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                  <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                  <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                  <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                  <option value="Australia/Lord_Howe">(GMT+10:30) Lord Howe Island</option>
                  <option value="Etc/GMT-11">(GMT+11:00) Solomon Is., New Caledonia</option>
                  <option value="Asia/Magadan">(GMT+11:00) Magadan</option>
                  <option value="Pacific/Norfolk">(GMT+11:30) Norfolk Island</option>
                  <option value="Asia/Anadyr">(GMT+12:00) Anadyr, Kamchatka</option>
                  <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                  <option value="Etc/GMT-12">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                  <option value="Pacific/Chatham">(GMT+12:45) Chatham Islands</option>
                  <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                  <option value="Pacific/Kiritimati">(GMT+14:00) Kiritimati</option>
                </select>
                <p className="text-sm text-gray-400 mb-0">The time zone use for the reports</p>
              </div>
              <div className="mt-4">
                <label>Pricing Plan</label>
              </div>
              <div className="col-span-4 mt-4">
                <div className="flex gap-5">
                  <div className="text-center">
                    <img
                      className=" mx-auto"
                      width={100}
                      height={100}
                      src={require("../../../assets/instatags.png")}
                    />
                    <h6>Basic</h6>
                  </div>
                  <div className="text-center opacity-25">
                    <img
                      className=" mx-auto"
                      width={100}
                      height={100}
                      src={require("../../../assets/instatags.png")}
                    />
                    <label>Advanced</label>
                  </div>
                  <div className="text-center opacity-25">
                    <img
                      className=" mx-auto"
                      width={100}
                      height={100}
                      src={require("../../../assets/instatags.png")}
                    />
                    <label>Plus</label>
                  </div>
                </div>
                <div className="text-center w-full max-w-md">
                  <a href="https://instatags.netlify.app/Pricing" target="__blank">
                    Explore more pricing plans
                  </a>
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-4">
              <h5>Users</h5>
            </div>
            <hr />
            <div className="grid grid-cols-5 space-y-5">
              <div className="mt-3">Labels</div>
              <div className="col-span-4">
                <TagsInput
                  inputProps={{ placeholder: "Add Labels" }}
                  value={tags && tags}
                  className="w-full max-w-sm border-2 border-gray-200 outline-gray-300 px-2"
                  onChange={(e) => setTags(e)}
                />
              </div>
            </div>
            <div className="flex justify-between mt-4">
              <h5>User Invites</h5>
            </div>
            <hr />
            <div className="grid grid-cols-5 space-y-5">
              <div className="">
                <label>Receives notifications on accepted invite</label>
              </div>
              <div className="col-span-4">
                <input
                  type="text"
                  className="w-full max-w-sm border-2 border-gray-200 outline-gray-300 py-1 px-2"
                />
                <p className="text-sm text-gray-400 mb-0">
                  Insta tag is a long established fact that distracted by the readable content of a
                  page when looking at its layout.
                </p>
              </div>
              <div className="">
                <label>Manual Invite URL</label>
              </div>
              <div className="col-span-4">
                <input
                  type="text"
                  className="w-full max-w-sm border-2 border-gray-200 outline-gray-300 py-1 px-2"
                />
                <p className="text-sm text-gray-400 mb-0">
                  Insta tag is a long established fact that distracted by the readable content of a
                  page when looking at its layout.
                </p>
              </div>
              <div className="">
                <label>Manual Invite send to</label>
              </div>
              <div className="col-span-4">
                <input
                  type="text"
                  className="w-full max-w-sm border-2 border-gray-200 outline-gray-300 py-1 px-2"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full border border-gray-300 px-3 py-3">
            <div className="flex justify-between">
              <h5>Tags</h5>
            </div>
            <hr />
            <div className="grid grid-cols-5 space-y-5">
              <div className="mt-4">
                <label>Default Material</label>
              </div>
              <div className="col-span-4">
                <select
                  className="w-full max-w-sm border-2 border-gray-200 outline-gray-300 py-1 px-2 text-gray-500"
                  onChange={(e) => setdefaultMaterial(e.target.value)}
                  value={defaultMaterial}
                >
                  <option>Materials</option>
                  {materials.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <p className="text-sm text-gray-400 mb-0">
                  The default material that new project will use
                </p>
              </div>
              <div className="">
                <label>Default Style</label>
              </div>
              <div className="col-span-4">
                <select
                  value={defaultStyle}
                  className="w-full max-w-sm border-2 border-gray-200 outline-gray-300 py-1 px-2 text-gray-500"
                  onChange={(e) => setdefaultStyle(e.target.value)}
                >
                  <option>Styles</option>
                  {styles.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <p className="text-sm text-gray-400 mb-0">
                  The default style that new project will use
                </p>
              </div>
            </div>

            <div className="mt-4">
              <div className="border border-gray-300 p-2">
                <label>Materials</label>
                <hr />
                <div className="grid grid-cols-2">
                  <div>
                    <div className="overflow-y-auto h-64">
                      {materialsArray.map((item) => (
                        <div className="ml-2">
                          <input
                            id="comments"
                            name="comments"
                            type="checkbox"
                            checked={
                              selectedMaterial &&
                              selectedMaterial.some((obj) => obj.name === item.name)
                            }
                            class="h-4 w-4 rounded border-gray-300 accent-indigo-500 focus:ring-indigo-500"
                            onClick={() => changeMaterials(item)}
                          ></input>
                          <label className="ml-2 text-gray-500 text-sm">{item.name}</label>
                        </div>
                      ))}
                    </div>
                    <h6 className="text-center mt-2 text-gray-500 text-sm">Available Materials</h6>
                  </div>
                  <div>
                    <div className="overflow-auto h-64">
                      {selectedMaterial.length > 0 ? (
                        selectedMaterial.map((item) => (
                          <div className="ml-2">
                            <label className="ml-2 text-gray-500 text-sm">{item.name}</label>
                          </div>
                        ))
                      ) : (
                        <h6 className="text-center mt-2 text-gray-500 text-sm">
                          Select any material from left panel
                        </h6>
                      )}
                    </div>
                    <h6 className="text-center mt-2 text-gray-500 text-sm">Selected Materials</h6>
                  </div>
                </div>
              </div>
              <div className="border border-gray-300 p-2">
                <label>Styles</label>
                <hr />
                <div className="grid grid-cols-2">
                  <div>
                    <div className="overflow-y-auto h-64">
                      {stylesArray.map((item) => (
                        <div className="ml-2">
                          <input
                            id="comments"
                            name="comments"
                            type="checkbox"
                            checked={
                              selectedStyle && selectedStyle.some((obj) => obj.name === item.name)
                            }
                            class="h-4 w-4 rounded border-gray-300 accent-indigo-500 focus:ring-indigo-500"
                            onClick={() => changeStyles(item)}
                          ></input>
                          <label className="ml-2 text-gray-500 text-sm">{item.name}</label>
                        </div>
                      ))}
                    </div>
                    <h6 className="text-center mt-2 text-gray-500 text-sm">Available Styles</h6>
                  </div>
                  <div>
                    <div className="overflow-auto h-64">
                      {selectedStyle.length > 0 ? (
                        selectedStyle.map((item) => (
                          <div className="ml-2">
                            <label className="ml-2 text-gray-500 text-sm">{item.name}</label>
                          </div>
                        ))
                      ) : (
                        <h6 className="text-center mt-2 text-gray-500 text-sm">
                          Select any style from left panel
                        </h6>
                      )}
                    </div>
                    <h6 className="text-center mt-2 text-gray-500 text-sm">Selected Styles</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
