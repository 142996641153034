import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  fileUpload,
  fileUploadAfterSignURL,
  getAllBands,
  getSingleUser,
  updateUserDetails,
} from "../../../actions";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { baseURl } from "../../../utlilities";

const UserDetails = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [brandArray, setbrandArray] = useState([]);
  const [labelsData, setlabelsData] = useState([]);
  const [routesData, setroutesData] = useState([]);
  const [brandsData, setbrandsData] = useState([]);
  const [accessLevel, setaccessLevel] = useState([]);
  const [imageId, setimageId] = useState();
  const [selectedImage, setselectedImage] = useState(null);

  const params = useParams();
  useEffect(() => {
    getSingleUser(params.id).then((res) => {
      setname(res.data.name);
      setemail(res.data.email);
      setaccessLevel(res.data.accessLevel);
      setimageId(res.data.image);
      debugger;
    });

    getAllBands().then((res) => {
      if (res.data) {
        setbrandArray(
          res.data.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          })
        );
      }
      // setisLoading(false);
    });
  }, []);

  const changeAccessFunc = (data) => {
    if (accessLevel.includes(data.value)) {
      setaccessLevel(accessLevel.filter((item) => item !== data.value));
    } else {
      setaccessLevel([...accessLevel, data.value]);
    }
  };

  const saveUserDetails = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    updateUserDetails(
      name,
      email,
      userDetails.role,
      accessLevel,
      ["abc", "efj"],
      routesData,
      brandsData,
      params.id,
      imageId
    ).then((res) => {
      if (res.status == 200) {
        toast.success("Information Updated Successfully!");
      } else if (res.status == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };

  console.log(accessLevel);

  const labelArray = [
    {
      value: "label 1",
      label: "label 1",
    },
    {
      value: "label 2",
      label: "label 2",
    },
    {
      value: "label 3",
      label: "label 3",
    },
  ];
  const routeArray = [
    {
      value: "route 1",
      label: "route 1",
    },
    {
      value: "route 2",
      label: "route 2",
    },
    {
      value: "route 3",
      label: "route 3",
    },
  ];

  const AccessData = [
    {
      id: 1,
      value: "BRAND",
      text: "Brands",
      label: "Ability to add, delete and modify Brands",
    },
    {
      id: 2,
      value: "PACKAGE",
      text: "Packages",
      label: "Ability to add, delete and modify Packages",
    },
    {
      id: 3,
      value: "CUSTOMER",
      text: "Customers",
      label: "Ability to add, delete and modify Customers",
    },
    {
      id: 4,
      value: "ACTIVITY",
      text: "Activity",
      label: "Ability to add, delete and modify Activity",
    },
    {
      id: 5,
      value: "PROJECT",
      text: "Projects",
      label: "Ability to add, delete and modify Project",
    },
  ];

  const uploadImage = () => {
    fileUpload(selectedImage.name).then((res) => {
      setimageId(res.data.id);
      fileUploadAfterSignURL(res.data.signUrl, selectedImage).then((res2) => {
        toast.success("Image Uploaded Successfully!");
        debugger;
      });
      debugger;
    });
  };
  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5>Users &gt; User Detail</h5>
        <button
          className="bg-indigo-500 px-4 text-white rounded-sm"
          onClick={() => saveUserDetails()}
        >
          Save
        </button>
      </div>

      <div className="bg-gray-50 max-w-xl mx-auto shadow-md px-3 py-3 my-4">
        <div className="w-full mb-10">
          <div className="flex bg-slate-200 space-x-1 rounded-lg">
            <button
              onClick={() => setactiveTab(1)}
              className={
                activeTab == 1
                  ? "w-full bg-indigo-500 py-2 text-white rounded-lg"
                  : "w-full bg-slate-200 py-2 rounded-lg hover:bg-slate-300"
              }
            >
              General
            </button>
            <button
              onClick={() => setactiveTab(2)}
              className={
                activeTab == 2
                  ? "w-full bg-indigo-500 py-2 text-white rounded-lg"
                  : "w-full bg-slate-200 py-2 rounded-lg hover:bg-slate-300"
              }
            >
              Access
            </button>
            <button
              onClick={() => setactiveTab(3)}
              className={
                activeTab == 3
                  ? "w-full bg-indigo-500 py-2 text-white rounded-lg"
                  : "w-full bg-slate-200 py-2 rounded-lg hover:bg-slate-300"
              }
            >
              Extras
            </button>
          </div>
        </div>
        {activeTab == 1 ? (
          <>
            <div className="flex">
              <div className="w-32">
                <h6 className="mb-0">Profile Picture</h6>
              </div>
              <div className="flex flex-col">
                {selectedImage && imageId !== "" ? (
                  <img className="w-32 h-32 mb-3" src={URL.createObjectURL(selectedImage)} />
                ) : (
                  <img className="w-32 h-32 mb-3" src={`${baseURl}/file/f/${imageId}`} />
                )}
                <input
                  type="file"
                  name="myImage"
                  onChange={(event) => {
                    console.log(event.target.files);
                    setselectedImage(event.target.files[0]);
                  }}
                />
                <div>
                  <button
                    onClick={() => uploadImage()}
                    className="w-50 bg-indigo-500 text-white rounded-full py-1 mt-2"
                  >
                    Change
                  </button>
                </div>
              </div>
            </div>
            <div className="flex mt-2">
              <div className="w-32">
                <h6 className="mb-0">Name</h6>
              </div>
              <div className="">
                <input
                  type="text"
                  className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
                  onChange={(e) => setname(e.target.value)}
                  value={name}
                />
              </div>
            </div>
            <div className="flex mt-2">
              <div className="w-32">
                <h6 className="mb-0">Email</h6>
              </div>
              <div className="">
                <input
                  disabled
                  type="name"
                  className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
                  onChange={(e) => setname(e.target.value)}
                  value={email}
                />
              </div>
            </div>
            <div className="flex mt-2">
              <div className="w-32">
                <h6 className="mb-0">Active</h6>
              </div>
              <div className="">
                <input
                  id="comments"
                  name="comments"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 accent-indigo-500 focus:ring-indigo-500"
                ></input>
                <label className="ml-2 text-gray-500 text-sm">
                  If the account isn't active, the user can't log in.
                </label>
              </div>
            </div>
            <hr />
            <div className="flex mt-2">
              <div className="w-32">
                <h6 className="mb-0">Last sign in</h6>
              </div>
              <div className="">
                <label>28/11/2022 @ 9:45:42am</label>
              </div>
            </div>
          </>
        ) : activeTab == 2 ? (
          <>
            <div className="flex mt-2">
              <div className="w-32">
                <h6 className="mb-0">Admin</h6>
              </div>
              <div className="">
                <input
                  id="comments"
                  name="comments"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 accent-indigo-500 focus:ring-indigo-500"
                ></input>
                <label className="ml-2 text-gray-500 text-sm">
                  An admin has the ability to access everything in Insta Tag
                </label>
              </div>
            </div>
            <hr />
            {AccessData.map((item, index) => (
              <div className="flex mt-2" key={index}>
                <div className="w-32">
                  <h6 className="mb-0">{item.text}</h6>
                </div>
                <div className="">
                  <input
                    id="comments"
                    name="comments"
                    type="checkbox"
                    checked={accessLevel && accessLevel.includes(item.value)}
                    class="h-4 w-4 rounded border-gray-300 accent-indigo-500 focus:ring-indigo-500"
                    onClick={() => changeAccessFunc(item)}
                  ></input>
                  <label className="ml-2 text-gray-500 text-sm">{item.label}</label>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <div className="flex mt-2">
              <div className="w-32">
                <h6 className="">Labels</h6>
              </div>
              <div className="w-full">
                <ReactSelect
                  defaultValue={labelsData}
                  onChange={setlabelsData}
                  options={labelArray}
                  isMulti={true}
                />
                <label className="ml-2 text-gray-500 text-sm">
                  Use labels to discribe and categorise your users.
                </label>{" "}
              </div>
            </div>
            <div className="flex mt-2">
              <div className="w-32 m-auto">
                <h6 className="">Routes</h6>
              </div>
              <div className="w-full">
                <ReactSelect
                  defaultValue={routesData}
                  onChange={setroutesData}
                  options={routeArray}
                  isMulti={true}
                />
                <label className="ml-2 text-gray-500 text-sm">
                  This help to work with your Routes accounting system.
                </label>{" "}
              </div>
            </div>
            <div className="flex mt-2">
              <div className="w-32 m-auto">
                <h6 className="">Brands</h6>
              </div>
              <div className="w-full">
                <ReactSelect
                  defaultValue={brandsData}
                  onChange={setbrandsData}
                  options={brandArray}
                  isMulti={true}
                />
                <label className="ml-2 text-gray-500 text-sm">
                  Limits the visible brands to the users by using the above labels.
                </label>{" "}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
