import React, { useState, Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  changeActivityStatus,
  getAllProjects,
  getAllProjectsPending,
  getProjectTag,
  getSingleProject,
} from "../../../actions";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import Spinner from "../../Common/Spinner";
import moment from "moment";
import { baseURl } from "../../../utlilities";

const Activity = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [projects, setprojects] = useState([]);
  const [projectName, setprojectName] = useState("");
  const [pdfFile, setpdfFile] = useState("");
  const [tagData, settagData] = useState([]);
  const [projectId, setprojectId] = useState("");
  const [email, setemail] = useState("");
  const [fileId, setfileId] = useState("");

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    onBeforePrint: () => console.log("hamza"),
    content: () => componentRef.current,
  });

  useEffect(() => {
    setisLoading(true);
    getAllProjectsPending("PENDING").then((res) => {
      if (res.status === 200) {
        setprojects(res.data);
        setisLoading(false);
      } else {
        setprojects([]);
      }
    });
  }, []);

  const projectDetailsFunc = (item) => {
    setfileId(item.file[0]);
    setprojectId(item.id);
    setemail(item.user?.email);
    setisLoading(true);
    getSingleProject(item.id)
      .then((res) => {
        setisLoading(false);
        setIsOpen(true);
        setprojectName(res.data.name);
        setpdfFile(res.data.pdfFile);
        getProjectTag(item.id).then((res) => {
          settagData(res.data);
        });
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const markDone = (id, value) => {
    changeActivityStatus(id, value).then((res) => {
      if (res.status == 201) {
        toast.success("Marked Successfully!");
        getAllProjectsPending("PENDING").then((res) => {
          if (res.status === 200) {
            setprojects(res.data);
            setisLoading(false);
          } else {
            setprojects([]);
          }
        });
        setIsOpen(false);
      } else if (res.data.statusCode == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };
  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5>Tools &gt; Activity</h5>
        {/* <button
          className="bg-indigo-500 px-4 text-white rounded-sm"
          onClick={() => setIsOpen(true)}
        >
          New
        </button> */}
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        projects.map((item, index) => (
          <div
            className="grid grid-cols-1 mt-5 cursor-pointer"
            onClick={() => projectDetailsFunc(item)}
            key={index}
          >
            <div className="flex justify-between bg-slate-100 px-4 py-2">
              <div>
                <label>{item.user?.name}</label> <br />
                <span className="text-gray-500 text-sm">{item.user?.email}</span>
              </div>
              <div className="m-auto">
                <label>{item.name}</label>
              </div>
              <div className="m-auto">
                <label className="text-gray-400 mb-0">{item.material}</label> <br />
                <label className="text-gray-400 italic text-sm">{item.style}</label>
              </div>
              <div className="m-auto">
                <label className="text-gray-500 text-sm">-</label>
              </div>
              <div className="m-auto">
                <label className="text-gray-500 text-sm">
                  {moment(item.updatedAt).format("MMM Do YY")}
                </label>
              </div>
            </div>
          </div>
        ))
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {projectName}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">by {email}</p>
                  </div>
                  {/* <div>
                    <div>
                      <input
                        id="comments"
                        name="comments"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 accent-indigo-500 focus:ring-indigo-500"
                      ></input>
                      <label className="ml-3">01 - legal case card</label>
                    </div>
                    <div>
                      <input
                        id="comments"
                        name="comments"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 accent-indigo-500 focus:ring-indigo-500"
                      ></input>
                      <label className="ml-3">02 - Windows sticker (4)</label>
                    </div>
                  </div> */}

                  <div className="flex justify-between mt-4">
                    <button
                      onClick={() => window.open(`${baseURl}/file/f/view/preview.pdf?id=${fileId}`)}
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      View
                    </button>
                    <button
                      onClick={() => window.open(`${baseURl}/file/f/${fileId}`)}
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      Download
                    </button>
                    <button
                      onClick={() => markDone(projectId, "REJECTED")}
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      Reject
                    </button>
                    <button
                      onClick={() => markDone(projectId, "APPROVED")}
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      Mark as done
                    </button>
                  </div>

                  <div className="grid grid-cols-2 mt-4">
                    {/* <div>
                      <h5>History</h5>
                      <div>
                        <p className="mb-0">Dellino morales made this project</p>
                        <span className="text-slate-500 text-sm">yesterday at 9:20 am</span>
                      </div>
                      <div className="mt-3">
                        <p className="mb-0">Dellino morales made this project</p>
                        <span className="text-slate-500 text-sm">yesterday at 9:20 am</span>
                      </div>
                    </div> */}
                    <div>
                      <h5>Details:</h5>
                      {tagData.map((item, index) => (
                        <div key={item.id} className="bg-slate-100 px-2 py-2 rounded-sm flex mt-3">
                          <div className="flex-col">
                            {item.brand?.map((brand) => (
                              <div className="w-full flex mt-2">
                                <img className="w-20" src={`${baseURl}/file/f/${brand.image}`} />
                                <p className="my-auto ml-2">{brand.name}</p>
                              </div>
                            ))}
                          </div>
                          <div className="m-auto text-center">
                            <p className="text-lg">${item.quantity}</p>
                            <p className="text-lg">{item.package.name}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div
        className="screen:hidden"
        dangerouslySetInnerHTML={{ __html: pdfFile }}
        ref={componentRef}
      ></div>
    </div>
  );
};

export default Activity;
