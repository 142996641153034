import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addPackage, getAllPackages } from "../../../actions";
import Spinner from "../../Common/Spinner";

const Packages = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setname] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [brandData, setbrandData] = useState([]);

  useEffect(() => {
    getAllPackages().then((res) => {
      setbrandData(res.data);
      setisLoading(false);
    });
  }, []);

  const addNewPackage = () => {
    addPackage(name, "-", "-").then((res) => {
      if (res.status == 201) {
        toast.success("Package Addedd Successfully!");
        getAllPackages().then((res) => {
          setbrandData(res.data);
          setisLoading(false);
        });
        setIsOpen(false);
      } else if (res.data.statusCode == 404) {
        toast.error("Error!");
      } else {
        toast.error("Error!");
      }
    });
  };

  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5>Packages</h5>
        <button
          className="bg-indigo-500 px-4 text-white rounded-sm"
          onClick={() => setIsOpen(true)}
        >
          New
        </button>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-1 mt-3">
          <div className="">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Aliases</th>
                  <th scope="col">Total oz.</th>
                </tr>
              </thead>
              <tbody>
                {brandData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Link className="no-underline" to={`/dashboard/PackageDetails/${item.id}`}>
                        <div className="flex">
                          {/* <img className="w-24" src={item.img} /> */}
                          <label className="mt-2 ml-2 text-black cursor-pointer">{item.name}</label>
                        </div>
                      </Link>
                    </td>
                    <td>
                      <label>{item.alias}</label> <br /> <label className="text-gray-400"></label>
                    </td>
                    <td>{item.once}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    New Packages
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Add new packages below</p>
                    <div className="w-full">
                      <textarea
                        onChange={(e) => setname(e.target.value)}
                        value={name}
                        rows={5}
                        className="w-full border-2 border-gray-200 outline-gray-300"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => addNewPackage()}
                    >
                      Create Package
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default Packages;
