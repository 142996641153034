import axios from "axios";
import { baseURl } from "./utlilities";

let accessToken = "";
if (localStorage.getItem("accessToken")) {
  accessToken = localStorage.getItem("accessToken");
}

const headersconfig = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
};
const headersconfigFile = {
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${accessToken}`,
  },
};

export const register = async (name, email, phoneNumber, password, distributor) => {
  const bodyconfig = {
    name,
    email,
    phoneNumber,
    password,
    distributor,
    role: "DISTRIBUTOR",
  };
  let res = {};
  await axios
    .post(baseURl + "/account/register", bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Register : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const login = async (email, password) => {
  const bodyconfig = {
    email,
    password,
  };
  let res = {};
  await axios
    .post(baseURl + "/account/login", bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Login : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const addBrand = async (name) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    name,
    userId: userId.id,
  };
  let res = {};
  await axios
    .post(baseURl + "/brand", bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Add Brand : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const importBrand = async (file) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));
  var bodyFormData = new FormData();
  debugger;
  bodyFormData.set("file", file);
  // const bodyconfig = {
  //   name,
  //   userId: userId.id,
  // };
  let res = {};
  await axios
    .post(baseURl + "/brand/import", bodyFormData, headersconfigFile)
    .then(function (response) {
      res = response;
      console.log("Import Brand : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};
export const importUsers = async (file) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));
  var bodyFormData = new FormData();
  debugger;
  bodyFormData.set("file", file);
  // const bodyconfig = {
  //   name,
  //   userId: userId.id,
  // };
  let res = {};
  await axios
    .post(baseURl + "/account/user/import", bodyFormData, headersconfigFile)
    .then(function (response) {
      res = response;
      console.log("Import Users : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};
export const importCustomers = async (file) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));
  var bodyFormData = new FormData();
  debugger;
  bodyFormData.set("file", file);
  // const bodyconfig = {
  //   name,
  //   userId: userId.id,
  // };
  let res = {};
  await axios
    .post(baseURl + "/customer/import", bodyFormData, headersconfigFile)
    .then(function (response) {
      res = response;
      console.log("Import Customers : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const exportBrand = async () => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    userId: userId.id,
  };
  let res = {};
  await axios
    .post(baseURl + "/brand/export", bodyconfig, headersconfigFile)
    .then(function (response) {
      res = response;
      console.log("Export Brand : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const updateBrand = async (name, image, alternateName, isActive, id, supplier) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    name,
    image,
    alternateName,
    isActive,
    supplier,
    userId: userId.id,
  };
  let res = {};
  await axios
    .put(baseURl + `/brand/${id}`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Update Brand : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getAllBands = async (name, orderBy, sortBy) => {
  let res = {};
  await axios
    .get(baseURl + `/brand?name=${name}&order=${orderBy}&sort=${sortBy}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Add Brand : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getSingleBand = async (id) => {
  let res = {};
  await axios
    .get(baseURl + `/brand/${id}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Single : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const addPackage = async (name, alias, once) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    name,
    alias,
    once,
    userId: userId.id,
  };
  let res = {};
  await axios
    .post(baseURl + "/package", bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Add Package : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getAllPackages = async () => {
  let res = {};
  await axios
    .get(baseURl + "/package", headersconfig)
    .then(function (response) {
      res = response;
      console.log("Packages : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getSinglePackages = async (id) => {
  let res = {};
  await axios
    .get(baseURl + `/package/${id}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Packages : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};
export const getSingleProject = async (id) => {
  let res = {};
  await axios
    .get(baseURl + `/project/${id}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Project Single : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const updatePackage = async (name, alias, once, id) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    name,
    alias,
    once,
    userId: userId.id,
  };
  let res = {};
  await axios
    .put(baseURl + `/package/${id}`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Add Package : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const updateProject = async (
  name,
  style,
  material,
  id,
  pdfFile,
  backgroudColor,
  priceColor
) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    name,
    style,
    material,
    pdfFile,
    backgroudColor,
    priceColor,
    userId: userId.id,
  };
  let res = {};
  await axios
    .put(baseURl + `/project/${id}`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Update Project : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const updateProjectLine = async (
  id,
  brandId,
  packageId,
  quantity,
  copies,
  alternateName,
  backgroudColor,
  priceColor,
  lineId
) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    brandId,
    packageId,
    quantity,
    copies,
    alternateName,
    backgroudColor,
    priceColor,
    userId: userId.id,
  };
  let res = {};
  await axios
    .put(baseURl + `/project/${id}/line/${lineId}`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Update Project Line: ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const addCustomer = async (name) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    name,
    userId: userId.id,
  };
  let res = {};
  await axios
    .post(baseURl + "/customer", bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Add Customer : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const makeProject = async (id) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    userId: userId.id,
  };
  let res = {};
  await axios
    .post(baseURl + `/project/${id}/make`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Make Project : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const changeActivityStatus = async (id, isDone) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    isDone,
    userId: userId.id,
  };
  let res = {};
  await axios
    .post(baseURl + `/project/${id}/activity/done`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Update Activity : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const createProject = async (name) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    name,
    userId: userId.id,
  };
  let res = {};
  await axios
    .post(baseURl + "/project", bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Create Project : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const createProjectTag = async (id, brandId, packageId, quantity) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));
  const bodyconfig = {
    brandId,
    packageId,
    quantity,
    userId: userId.id,
  };
  let res = {};
  await axios
    .post(baseURl + `/project/${id}/line`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Create Project Tag: ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const sendUserInvite = async (name, email, role) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));
  const bodyconfig = {
    name,
    email,
    role,
    distributor: userId.distributor.name,
  };
  let res = {};
  await axios
    .post(baseURl + `/account/invite`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("User invite API: ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};
export const getProjectTag = async (id) => {
  let res = {};
  await axios
    .get(baseURl + `/project/${id}/line`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("getProjectTag : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getAllProjectsPending = async (isDone) => {
  let res = {};
  await axios
    .get(baseURl + `/project?isDone=${isDone}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("project : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};
export const getAllProjects = async () => {
  let res = {};
  await axios
    .get(baseURl + `/project`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("project : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getSingleCustomers = async (id) => {
  let res = {};
  await axios
    .get(baseURl + `/customer/${id}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Customers : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};
export const getSingleUser = async (id) => {
  let res = {};
  await axios
    .get(baseURl + `/account/user/${id}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("User Details : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const updateUserDetails = async (
  name,
  email,
  role,
  accessLevel,
  label,
  route,
  brandFilter,
  id,
  image
) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    name,
    email,
    role,
    accessLevel,
    label,
    route,
    brandFilter,
    image,
    userId: userId.id,
  };
  let res = {};
  await axios
    .put(baseURl + `/account/user/${id}`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Update User Details : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getAllCustomers = async (name) => {
  let res = {};
  await axios
    .get(baseURl + `/customer?name=${name}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Get Customers : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};


// admin distributuer users

export const getAdminDistributorUsers = async (adminDistributoruserId) => {
    
  let res = {};
  await axios
    .get(baseURl + `/admin/user?distributorId=${adminDistributoruserId}`, headersconfig)
    .then(function (response) {
      res = response;

      // alert(JSON.stringify(response))
      console.log("Get admin distributors users : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res
};



//  admin distributuer users end


export const getAllUsers = async (name) => {

  let res = {};
  await axios
    .get(baseURl + `/account/user?name=${name}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Get Users : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getSingleDistributorDetails = async (id) => {
  let res = {};
  await axios
    .get(baseURl + `/distributor/${id}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Get Single distributor : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getAllDistributors = async () => {
  let res = {};
  await axios
    .get(baseURl + "/admin/distributor", headersconfig)
    .then(function (response) {
      res = response;
      console.log("Get distributor : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getAllMaterials = async (id) => {
  let res = {};
  await axios
    .get(baseURl + `/distributor/${id}/material`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Get Materials : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getAllStyles = async (id) => {
  let res = {};
  await axios
    .get(baseURl + `/distributor/${id}/style`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Get Styles : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const updateDistributor = async (name, status, id, plan) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    name,
    status,
    plan,
    userId: userId.id,
  };
  let res = {};
  await axios
    .put(baseURl + `/admin/distributor/${id}`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Update Distributor : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};
export const updateSingleDistributor = async (
  name,
  status,
  id,
  plan,
  image,
  motto,
  timezone,
  label,
  defaultMaterial,
  selectedMaterial,
  defaultStyle,
  selectedStyle
) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    name,
    status,
    plan,
    image,
    motto,
    timezone,
    label,
    defaultMaterial,
    selectedMaterial,
    defaultStyle,
    selectedStyle,
  };
  let res = {};
  await axios
    .put(baseURl + `/distributor/${id}`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Update Distributor : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const updateCustomer = async (
  name,
  address,
  city,
  state,
  zip,
  saleRoute,
  deliveryRoute,
  group,
  subGroup,
  id
) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    name,
    address,
    city,
    state,
    zip,
    saleRoute,
    deliveryRoute,
    group,
    subGroup,
    userId: userId.id,
  };
  let res = {};
  await axios
    .put(baseURl + `/customer/${id}`, bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Update customer : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const deleteCustomer = async (id) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    userId: userId.id,
  };
  let res = {};
  await axios
    .delete(baseURl + `/customer/${id}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Delete customer : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};
export const deleteProject = async (id) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    userId: userId.id,
  };
  let res = {};
  await axios
    .delete(baseURl + `/project/${id}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Delete Project : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const deleteProjectLine = async (id, lineId) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    userId: userId.id,
  };
  let res = {};
  await axios
    .delete(baseURl + `/project/${id}/line/${lineId}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Delete Project Line : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};
export const deleteBrand = async (id) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  // const bodyconfig = {
  //   userId: userId.id,
  // };
  let res = {};
  await axios
    .delete(baseURl + `/brand/${id}`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Delete Brand : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const fileUpload = async (filename) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const bodyconfig = {
    filename,
    userId: userId.id,
  };
  let res = {};
  await axios
    .post(baseURl + "/file", bodyconfig, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Add File : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const fileUploadAfterSignURL = async (url, file) => {
  const userId = JSON.parse(localStorage.getItem("userDetails"));

  const headersList = {
    Accept: "*/*",
    "Content-Type": "application/octet-stream",
  };

  let res = {};

  const reqOptions = {
    url: url,
    method: "PUT",
    headers: headersList,
    data: file,
  };
  const response = await axios(reqOptions);

  return response;
};

export const getAllMaterialsDefined = async () => {
  let res = {};
  await axios
    .get(baseURl + `/Material`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Get Materials From API : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};

export const getAllStylesDefined = async () => {
  let res = {};
  await axios
    .get(baseURl + `/style`, headersconfig)
    .then(function (response) {
      res = response;
      console.log("Get Style From API : ", response);
      return res;
    })
    .catch(function (error) {
      res = error.response;
      console.log(error);
      return error;
    });
  return res;
};
