import React from "react";

const Pacific = (props) => {
  return (
    <div>
      <div className="flex mt-4">
        <div
          className="flex gap-3 border border-gray-100 px-3 py-3"
          style={{ backgroundColor: props.backgroundColor }}
        >
          <div className="w-full flex">
            <img className="w-60 h-50 m-auto" src={require("../../../assets/instatags.png")} />
          </div>
          <div className="w-full my-auto text-center" style={{ color: props.priceColor }}>
            <p className="mb-0">
              <span className="text-5xl font-[1000]"> $4.99</span>
              <br />
              <span className="text-sm">PLUS CRV * PLUS TAX</span>
            </p>
            <p className="my-auto text-xl">4pk 16oz Cans</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pacific;
