import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Customer = () => {
  return (
    <div>
      <Header />
      <div className="">
        <div className="py-4">
          <div className="text-center">
            <label className="font-bold text-indigo-600 text-lg">
              Still not sure? Need more info?
            </label>
            <br />
            <label>Insta Tag was built from the ground up to get you making signage fast.</label>
          </div>
        </div>
        <div className="py-4">
          <div className="text-center">
            <label className="font-bold text-indigo-600 text-lg">
              Still not sure? Need more info?
            </label>
            <br />
            <label>Insta Tag was built from the ground up to get you making signage fast.</label>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:mx-14">
        <div className="mx-4 max-w-xl">
          <div className="bg-indigo-100 px-4 py-8 shadow-md">
            <p className="my-1">
              "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum. asd"
            </p>
          </div>
          <p className="font-medium py-2 px-4">
            Excepteur sint occaecat cupidatat non proident hamza
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 md:mx-14 justify-center">
        <div className="md:col-end-3 mx-4 max-w-xl">
          <div className="bg-indigo-100 px-4 py-8 shadow-md">
            <p className="my-1">
              "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum."
            </p>
          </div>
          <p className="font-medium py-2 px-4">Excepteur sint occaecat cupidatat non proident</p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:mx-14">
        <div className="mx-4 max-w-xl">
          <div className="bg-indigo-100 px-4 py-8 shadow-md">
            <p className="my-1">
              "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum. asd"
            </p>
          </div>
          <p className="font-medium py-2 px-4">
            Excepteur sint occaecat cupidatat non proident hamza
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 md:mx-14 justify-center">
        <div className="md:col-end-3 mx-4 max-w-xl">
          <div className="bg-indigo-100 px-4 py-8 shadow-md">
            <p className="my-1">
              "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum."
            </p>
          </div>
          <p className="font-medium py-2 px-4">Excepteur sint occaecat cupidatat non proident</p>
        </div>
      </div>

      <div className="py-5 bg-indigo-100">
        <div className="text-center">
          <label>Insta Tag was built from the ground up to get you making signage fast.</label>
          <br />
          <label>
            Give us a call @ <b>000-000-0000</b> or email
          </label>
        </div>
        <div className="flex justify-center p-4 text-center">
          <div className="max-w-lg md:px-10 px-4 py-3 rounded-full text-center bg-indigo-500 text-white">
            <label className="md:text-2xl text-lg">Get started now, it's free for 30 days</label>
          </div>
        </div>
        <div className="flex justify-center text-center">
          <label className="text-center md:basis-1/3 basis-1/1">
            Seriously, try it absolutely free with no credit card required. Let’s find out if
            Instatags is right for you. Spoiler: it probably is!
          </label>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Customer;
