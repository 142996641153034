import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

const Contact = () => {
  return (
    <div>
      <Header />
      <div className="container pb-10 pt-10">
        <div className="w-full mt-5 md:justify-around sm:flex">
          <div className="max-w-md">
            <h4>Questions? Let's chat.</h4>
            <label className="text-lg">
              The only thing better than using instags is talking about it.
            </label>

            <div className="space-y-6">
              <input
                type="text"
                placeholder="Name"
                className="outline outline-1 outline-slate-400 px-2 w-full max-w-sm py-2 rounded-md"
              />
              <input
                type="text"
                placeholder="Email"
                className="outline outline-1 outline-slate-400 px-2 w-full max-w-sm py-2 rounded-md"
              />
              <textarea
                type="text"
                placeholder="Message"
                className="outline outline-1 outline-slate-400 px-2 w-full max-w-sm py-2 rounded-md"
              />
              <br />
              <button className="bg-indigo-500 text-white px-5 py-2 rounded-full">
                Send
              </button>
            </div>
          </div>
          <div className="max-w-xl hidden md:flex">
            <img
              className="text-center w-3/4 h-3/3"
              src={require("../assets/contact.jpg")}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
