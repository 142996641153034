import React, { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getSingleCustomers, updateCustomer, deleteCustomer } from "../../../actions";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const CustomerDetails = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const [name, setname] = useState("");
  const [id, setid] = useState("");
  const [address, setaddress] = useState("");
  const [salesRouter, setsalesRouter] = useState("");
  const [city, setcity] = useState("");
  const [deliveryRoute, setdeliveryRoute] = useState("");
  const [state, setstate] = useState("");
  const [group, setgroup] = useState("");
  const [zip, setzip] = useState("");
  const [subGroup, setsubGroup] = useState("");

  const params = useParams();

  useEffect(() => {
    getSingleCustomers(params.id).then((res) => {
      debugger;
      if (res) {
        setname(res.data.name);
        setid(res.data.id);
        setsalesRouter(res.data.saleRoute);
        setcity(res.data.city);
        setdeliveryRoute(res.data.deliveryRoute);
        setstate(res.data.state);
        setgroup(res.data.group);
        setsubGroup(res.data.subGroup);
        setzip(res.data.zip);
        setaddress(res.data.address);
      }
    });
  }, []);

  const updateNewCustomer = () => {
    setisLoading(true);
    updateCustomer(
      name,
      address,
      city,
      state,
      zip,
      salesRouter,
      deliveryRoute,
      group,
      subGroup,
      params.id
    ).then((res) => {
      if (res.status == 200) {
        toast.success("Customer Updated Successfully!");
        setisLoading(false);
      } else if (res.status == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };

  const deleteNewCustomer = () => {
    setisLoading(true);
    deleteCustomer(params.id).then((res) => {
      if (res.status == 200) {
        toast.success("Customer Deleted Successfully!");
        setisLoading(false);
      } else if (res.status == 404) {
        toast.error("Error!");
        debugger;
      } else {
        toast.error("Error!");
      }
    });
  };

  return (
    <div className="container">
      <div className="flex justify-between bg-gray-50 px-4 py-3 shadow-md mt-2">
        <h5>Customers &gt; Customer Detail</h5>
        <button
          className="bg-indigo-500 px-4 text-white rounded-sm disabled:opacity-75 hover:opacity-90"
          onClick={() => updateNewCustomer()}
          disabled={isLoading}
        >
          Save
        </button>
      </div>

      <div className="bg-gray-50 max-w-3xl mx-auto shadow-md px-3 py-3 my-4">
        <div className="flex gap-3">
          <div>
            <label className="font-bold text-sm">Account Number</label>
            <input
              disabled={true}
              type="text"
              className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
              value={id}
            />
          </div>
          <div className="w-full">
            <label className="font-bold text-sm">Name</label>
            <input
              type="name"
              className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
              onChange={(e) => setname(e.target.value)}
              value={name}
            />
          </div>
        </div>
        <hr />

        <div className="flex justify-between gap-6">
          <div className="w-full">
            <label className="font-bold text-sm">Street address</label>
            <input
              type="text"
              className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
              onChange={(e) => setaddress(e.target.value)}
              value={address}
            />
          </div>
          <div className="w-full">
            <label className="font-bold text-sm">Sales route</label>
            <input
              type="name"
              className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
              onChange={(e) => setsalesRouter(e.target.value)}
              value={salesRouter}
            />
          </div>
        </div>

        <div className="flex justify-between gap-6 mt-3">
          <div className="w-full">
            <label className="font-bold text-sm">City</label>
            <input
              type="text"
              className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
              onChange={(e) => setcity(e.target.value)}
              value={city}
            />
          </div>
          <div className="w-full">
            <label className="font-bold text-sm">Delivery route</label>
            <input
              type="name"
              className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
              onChange={(e) => setdeliveryRoute(e.target.value)}
              value={deliveryRoute}
            />
          </div>
        </div>

        <div className="flex justify-between gap-6 mt-3">
          <div className="w-full">
            <label className="font-bold text-sm">State</label>
            <input
              type="text"
              className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
              onChange={(e) => setstate(e.target.value)}
              value={state}
            />
          </div>
          <div className="w-full">
            <label className="font-bold text-sm">Group</label>
            <input
              type="name"
              className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
              onChange={(e) => setgroup(e.target.value)}
              value={group}
            />
          </div>
        </div>

        <div className="flex justify-between gap-6 mt-3">
          <div className="w-full">
            <label className="font-bold text-sm">ZIP</label>
            <input
              type="number"
              className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
              onChange={(e) => setzip(e.target.value)}
              value={zip}
            />
          </div>
          <div className="w-full">
            <label className="font-bold text-sm">Subgroup</label>
            <input
              type="name"
              className="w-full border-2 border-gray-200 outline-gray-300 px-1 py-1"
              onChange={(e) => setsubGroup(e.target.value)}
              value={subGroup}
            />
          </div>
        </div>

        <button
          className="bg-red-600 text-white px-2 py-1.5 rounded-md mt-3 disabled:opacity-75 hover:opacity-90"
          disabled={isLoading}
          onClick={() => deleteNewCustomer()}
        >
          Remove...
        </button>
      </div>
    </div>
  );
};

export default CustomerDetails;
